import React, { useState } from 'react';
import { Collapse, Grid, Typography } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import FilePath from './filePathComponents/FilePath';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

export default function FilePaths(props: any) {
    const { imagePaths, cloudPaths, xmlPaths, rawImagePaths, rawCloudPaths, rawXmlPaths } = props;
    const [open, setOpen] = useState<boolean>(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const styles = {
        filesText: {
            fontWeight: 500,
        },
        fileContainer: {
            border: '2px solid',
            borderColor: '#D3D3DE',
            mt: .5
        }
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            sx={{mt: 1}}
        >
            <Grid item md={11} xs={12} >

                <ListItemButton sx={{ backgroundColor: '#F8F8F9' }} onClick={handleClick} >
                    <ListItemText>
                        <Typography color="#636383" sx={styles.filesText}>Files</Typography>
                    </ListItemText>
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Grid container columns={{ md: 10 }} spacing={2}>
                        {imagePaths.map((path: string, index: number) => {
                            return (
                                <FilePath key={index} path={path} rawPath={rawImagePaths[index]}></FilePath>
                            )
                        })}
                        {cloudPaths.map((path: string, index: number) => {
                            return (
                                <FilePath key={index} path={path} rawPath={rawCloudPaths[index]}></FilePath>
                            )
                        })}
                        {xmlPaths.map((path: string, index: number) => {
                            return (
                                <FilePath key={index} path={path} rawPath={rawXmlPaths[index]}></FilePath>
                            )
                        })}
                    </Grid>
                </Collapse>
            </Grid>
        </Grid>
    )
}