import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Grid } from '@mui/material'
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const styles = {
    textSettingBox: {
        backgroundColor: '#FFFFFF',
        textArea: {
            color: '#4B4E65',
        },
        mt: 1
    },
    text: {
        "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#808080",
        },
        input: {
            "&::placeholder": {
                opacity: 1,
            },
        },
    }
}

export default function TextSetting (props: any) {
    const { element, display_name, updateMachineLocation, disabledState, key, inputType, passwordState, navigateToMatchmaking } = props
    const [value, setValue] = useState<string>(element['value']);
    const [showPassword, setShowPassword] = useState(false);

    const handleChange = function (event: any) {
        setValue(event.target.value);
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const iconAdornment = passwordState
    ?  <InputAdornment position="end">
                <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>
    : ""

    return (
        <Grid key={key} item xs={12} lg={6} sx={styles.textSettingBox}>
            <TextField type={showPassword ? "text" : inputType} variant="outlined" disabled={disabledState} size="small" fullWidth value={value}
                sx={styles.text}
                onChange={(e: any) => {
                    handleChange(e)
                }}
                onBlur={(e: any) => {
                    if (display_name === 'Location') {
                        updateMachineLocation(e.target.value.trim())
                    }
                    element.Set(e.target.value.trim())
                        .catch(() => {
                            navigateToMatchmaking();
                        })
                }}
                InputProps={{
                    autoComplete: 'new-password',
                    startAdornment: <InputAdornment sx={styles.textSettingBox.textArea} position="start">{display_name} : </InputAdornment>,
                    endAdornment: iconAdornment,
                }}
            />
        </Grid>
    );
}