import { Grid, Typography } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

const styles = {
    filesText: {
        fontWeight: 500,
    },
    fileContainer: {
        border: '2px solid',
        borderColor: '#D3D3DE',
        mt: .5
    }
}

export default function FilePath(props: any) {
    const { path, rawPath} = props

    const fileSplit = rawPath.split('/')
    const fileName = fileSplit.slice(-1)

    return (
        <Grid item md={2}>
            <ListItemButton sx={styles.fileContainer} href={path} target="_blank" rel="noopener noreferrer">
                <ListItemText>
                    <Typography color="#636383" sx={styles.filesText}>{fileName}</Typography>
                </ListItemText>
            </ListItemButton>
        </Grid>
    )
}