import _ from 'lodash'

export function checkMachineResponse (response: any, eventType: string) {
    if (_.get(response, `Responses.${eventType}`)) {
        return true
    } return false
}

export function checkMachineResponseSuccessCode (response: any, eventType: string) {
    if (_.get(response, `Responses.${eventType}.code`) === "0") {
        return true
    } return false
}

export function checkMachineResponseSuccessCodeExport(response: any, eventType: string) {
    let statusCodeNum = parseInt(_.get(response, `Responses.${eventType}.EndpointResponse.StatusCode`))
    console.log(statusCodeNum)
    if ((_.get(response, `Responses.${eventType}.code`) === "0") && (statusCodeNum >= 200 && statusCodeNum < 400)) {
        return true
    } return false
}

export function retrieveErrorDescription (response: any, eventType: string) {
    if (_.get(response, `Responses.${eventType}.description`)) {
        return response.Responses[eventType].description
    } return "Unknown error."
}

export function retrieveErrorDescriptionExport(response: any, eventType: string) {
    if (_.get(response, `Responses.${eventType}.EndpointResponse`)) {
        return ` ${response.Responses[eventType].EndpointResponse.StatusCode} ${response.Responses[eventType].EndpointResponse.Reason}`
    } return "Unknown error."
}