import { Box, InputAdornment, TextField } from "@mui/material";

const styles = {
    text: {
        "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#808080",
        },
        input: {
            "&::placeholder": {
                opacity: 1,
            },
        },
        pt: 0.5
    },
    textSettingBox: {
        backgroundColor: '#FFFFFF',
        textArea: {
            color: '#4B4E65',
        },
    },
}

export default function CertificationTextField (props: any) {
    const { label, labelValue, addIcon, icon_source } = props

    const iconAdornment = addIcon
        ? <InputAdornment position="end">
            <Box
                component="img"
                sx={{ height: 24, pl: 2 }}
                alt="Parcel logo."
                src={icon_source}
            />
        </InputAdornment>
        : ""


    return (
        <TextField variant="outlined" size="small" disabled fullWidth value={labelValue}
            sx={styles.text}
            InputProps={{
                startAdornment: <InputAdornment sx={styles.textSettingBox.textArea} position="start">{label}: </InputAdornment>,
                endAdornment: iconAdornment
            }}
        />
    )
}