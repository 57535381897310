import React from 'react';
import { useState, useEffect } from 'react';
import { Grid, Box, Modal, Button, Typography, CircularProgress } from '@mui/material'
import { useNavigate } from "react-router-dom";

export default function Calibration(props: any) {
    let inititalState = {
        code: "0",
        NextStep: "BACKGROUND",
        Instructions: "Calibrate Your Machine",
        Image: "",
        ButtonText: "Begin Calibration",
    }
    const { isOpen, setIsOpen, setDisableScanButton } = props
    const { machine } = props
    const [phase, setPhase] = useState<string>("START");
    const [calibrationStatus, setCalibrationStatus] = useState<any>(inititalState);
    const [calibrationActive, setCalibrationActive] = useState<boolean>(false);
    const [instructionTextColor, setInstructionTextColor] = useState<string>('primary.main');

    const navigate = useNavigate();

    // this state will be moved to the catch of the first / subsequent calls if there is an error

    const retrieveStep = async function (calibrationPhase: string) {
        setCalibrationActive(true)
        machine.connection.calibrate(calibrationPhase)
            .then((data: any) => {
                setCalibrationActive(false)
                const calPath = data['Responses']['Calibrate']
                console.log("cal Path", calPath)

                // Calibration Error handling
                if (calPath["code"] !== "0") {
                    setCalibrationStatus((old: any) => {
                        setInstructionTextColor('error.main')
                        let copy = { ...old }
                        copy.Instructions = calPath["description"] + `\n Please Try Again`
                        copy.ButtonText = "Retry"
                        return copy
                    })
                    return
                }
                setInstructionTextColor('primary.main')

                machine.connection.file(calPath['Image'])
                    .then((img: string) => {
                        calPath.Image = img;
                        setCalibrationStatus(calPath);
                    })
            })
            .catch(() =>{
                alert("Failed to connect to machine, redirecting to matchmaking");
                navigate("/matchmaking");
            })
    }

    useEffect(() => {
        if (!isOpen) return;
        retrieveStep('START')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, machine])

    useEffect(() => {
        setPhase(calibrationStatus["NextStep"])
    }, [calibrationStatus])

    useEffect(() => {
        if (phase === "CAL_DONE") {
            setDisableScanButton(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phase])

    const toggleCalibrationSettings = function () {
        setCalibrationStatus(inititalState);
        return setIsOpen(!isOpen);
    }

    const styles = {
        modal: {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            width: {
                xs: "90%",
                sm: 'auto',
                md: "auto"
            },
            height: {
                xs: 'auto',
                sm: 'auto',
                md: "auto",

            },
            boxShadow: 24,
            p: 4,
            overflow: 'auto',
            borderRadius: '16px'
        },
        nextButton: {
            fontWeight: 700,
            fontSize: 15,
        },
        closeButton: {
            fontWeight: 600,
            float: {
                xs: 'center',
                md: 'right'
            },
        },
        calibrationText: {
            fontSize: 28,
            fontWeight: 500,
            color: '#262D6D',
        },
        CircularProgress: {
            mr: 1,
            color: "#FFFFFF"
        },
        instructionText: {
            fontWeight: 500,
            fontSize: 20,
            color: instructionTextColor,
            pt: 1,
            pb: 1
        },
        completeText: {
            fontSize: 36,
            fontWeight: 750,
            color: '#262D6D'
        }
    }

    return (
        <Modal
            open={isOpen}
            onClose={toggleCalibrationSettings}
        >
            <Box sx={styles.modal} style={{ textAlign: 'center' }}>
                <Grid container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center">
                    {phase !== "CAL_DONE" ? (
                        <React.Fragment>
                            <Grid item md={12} xs={12}>
                                <Button className="Blue-Button" sx={styles.closeButton} variant='outlined' onClick={toggleCalibrationSettings}>Exit Calibration</Button>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Typography sx={styles.calibrationText}>Calibrating: {machine.machineName}</Typography>
                            </Grid>
                            <Grid item md={12} xs={12} sx={{ pt: 2 }}>
                                {!calibrationStatus["Image"] ?
                                    <CircularProgress size={200} sx={{ color: "#F48430" }} />
                                    :
                                    <Box
                                        component="img"
                                        sx={{

                                            maxWidth: {
                                                xs: '200px',
                                                sm: '350px',
                                                md: '700px'
                                            },
                                            maxHeight: {
                                                xs: '200px',
                                                sm: '350px',
                                                md: '500px'
                                            },
                                            display: 'in-line',
                                            width: 'auto',
                                            height: 'auto'
                                        }}
                                        alt="Your logo."
                                        src={calibrationStatus["Image"]}
                                    />
                                }
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Typography sx={styles.instructionText}>{calibrationStatus["Instructions"]}</Typography>
                            </Grid>
                            {
                                !calibrationActive ?
                                    <Grid item md={12} xs={12} sx={{ pt: 3 }}>
                                        <Button className="Orange-Button" sx={styles.nextButton} onClick={() => retrieveStep(phase)}>{calibrationStatus["ButtonText"]}</Button>
                                    </Grid>
                                    :
                                    <Grid item md={12} xs={12} sx={{ pt: 3 }}>
                                        <Button className="Orange-Button" sx={styles.nextButton} disabled onClick={() => retrieveStep(phase)}><CircularProgress sx={styles.CircularProgress} size={15} /> {calibrationStatus["ButtonText"]}</Button>
                                    </Grid>
                            }
                        </React.Fragment>
                    )
                        :
                        <React.Fragment>
                            <Grid item md={12} xs={12}>
                                <Button className="Blue-Button" sx={styles.closeButton} variant="outlined" onClick={toggleCalibrationSettings}>Close Calibration Settings</Button>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-evenly"
                                alignItems="center"
                            >
                                <Grid item md={12} xs={12}>
                                    <Typography sx={styles.completeText}> Calibration complete, please close to continue scanning </Typography>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>
            </Box>
        </Modal>
    )
}