import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';

export default function DimensionFields(props: any) {
    const { styling, helperStyling, dimension, unit, indicator } = props
    
    function hexToColor(hexColor: string) {
        switch (true) {
            case hexColor === "#369350":
                return 'green'
            case hexColor === "#1627BA":
                return 'blue'
            case hexColor === "#D43636":
                return 'red'
            case hexColor === "#707094":
                return 'gray'
        }
    }

    return (
        <Grid item md={4} xs={11} >
            <TextField id="length_field" variant="outlined" fullWidth focused 
                label={indicator}
                value={dimension}
                sx={styling}
                helperText={unit} //note you can put units under the text field if we want to save some room
                FormHelperTextProps={{ style: helperStyling}} 
                InputLabelProps={{
                    style: { 
                        fontSize: 30,
                        color : hexToColor(helperStyling["color"]),
                        marginTop: -5
                    }
                }}
                // multiline //you can put multiline to allow it to wrap and grow to accomodate the full value
                InputProps={{
                    readOnly: true,
                    style: {
                        fontSize: 40
                    },
                    sx: {
                        "& input": {
                            textAlign: "center"
                        }
                    }
                }}
            />
        </Grid>
    )
}