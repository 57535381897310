import { createTheme } from '@mui/material'

// main themes colors
let theme = createTheme({
    palette: {
        background: {
            default: '#F5F5F8'
        },
        primary: {
            light: '#000075',
            main: '#00004B',
            dark: '#000020',
        },
        secondary: {
            light: '#F8B160',
            main: '#F7914B',
            dark: '#F48430',
        },
        error: {
            main: '#ff0f0f'
        }
        // not used - Error / Warning / Info / Success

    },
    typography: {
        button: {
            textTransform: "none",
        },
        fontFamily: [
            'Open Sans', 'sans - serif'
        ].join(',')
    },
})

export default createTheme(theme, {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.Scan-Button': {
                        color: "#FFFFFF",
                        backgroundColor: theme.palette.secondary.main,
                        height: '65px',
                        fontWeight: 700,
                        fontSize: 20,
                        '&:hover': {
                            backgroundColor: theme.palette.secondary.dark,
                            borderColor: 'black',
                            borderStyle: 'solid',
                            borderWidth: 1,
                        },
                        '&:disabled': {
                            backgroundColor: '#ffcaa1',
                            border: 1,
                            color: "#FFFFFF"
                        }
                    },
                    '&.Orange-Button': {
                        color: "#FFFFFF",
                        backgroundColor: theme.palette.secondary.main,
                        '&:hover': {
                            backgroundColor: '#F48430',
                        },
                        '&:disabled': {
                            backgroundColor: '#ffcaa1',
                            border: 1,
                            color: "#FFFFFF"
                        }
                    },
                    '&.Blue-Button': {
                        color: "#FFFFFF",
                        backgroundColor: theme.palette.primary.light,
                        '&:hover': {
                            backgroundColor: '#191E48',
                        },
                        '&:disabled': {
                            backgroundColor: 'red',
                            color: "#FFFFFF"
                        },
                        '&.Mui-disabled': {
                            color: "#FFFFFF",
                            backgroundColor: '#D3D3DE'
                        },
                    },
                    '&.Upload-Export-Blue': {
                        color: "#FFFFFF",
                        backgroundColor: theme.palette.primary.main,
                        height: '65px',
                        fontWeight: 700,
                        fontSize: 20,
                        '&:hover': {
                            backgroundColor: '#191E48',
                            border: 1,
                            borderColor: '#FFFFFF'
                        },
                        '&:disabled': {
                            color: "#FFFFFF",
                            backgroundColor: '#D3D3DE'
                        }
                    },
                    '&.Nav-Bar-Button': {
                        backgroundColor: '#2B3167',
                        color: "#FFFFFF",
                        '&:hover': {
                            backgroundColor: '#384085',
                        },
                    },
                    '&.Sign-In-Button': {
                        color: "#FFFFFF",
                        backgroundColor: '#F48430',
                        height: '65px',
                        fontWeight: 700,
                        fontSize: 20,
                        '&:hover': {
                            backgroundColor: '#191E48',
                            border: 1,
                            borderColor: '#FFFFFF'
                        },
                        '&:disabled': {
                            backgroundColor: '#D3D3DE',
                            color: "#FFFFFF"
                        }
                    }
                },
            },
        },
    }
})