import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import DimensionField from './dimensionFieldComponents/DimensionField';

export default function DimensionFields(props: any) {
    const { cargoResponse, selectedScanIndex } = props
    const [units, setUnits] = useState<string[]>(["", "", ""]);
    const [dimensionsAndWeight, setDimensionsAndWeight] = useState<any[]>(["", "", "", "", "", "", "", "", ""]);
    const [certifiedDimension, setCertifiedDimensions] = useState<any[]>([true, true, true])

    useEffect(() => {
        if (cargoResponse) {
            const dimensionBounds = cargoResponse.dimensions.dimensionBounds;
            const cargoDims = cargoResponse.dimensions
            const cargoUnits = cargoResponse.units
            let lengthWidthHeight = [cargoDims.length, cargoDims.width, cargoDims.height, cargoDims.volume, cargoDims.weight.display, cargoDims.density]
            setDimensionsAndWeight(lengthWidthHeight)
            setUnits([cargoUnits.length, cargoUnits.weight, cargoUnits.volume, cargoUnits.density])
            setCertifiedDimensions([dimensionBounds.length && dimensionBounds.overall, dimensionBounds.width && dimensionBounds.overall, dimensionBounds.height && dimensionBounds.overall])
        }
    }, [selectedScanIndex, cargoResponse])

    const backgroundColor = '#EAEAF0'
    const helperBackgroundColor = '#E2E2E9'
    const helperSize = 20
    const lengthColor = "#369350"
    const widthColor = "#1627BA"
    const heightColor = "#D43636"
    const outOfBoundsColor = "#F1C21B"
    const standardColor = "#707094"
    const styles = {
        lengthTextArea: {
            "& .MuiInputBase-root.Mui-focused": {
                "& > fieldset": {
                    borderColor: certifiedDimension[0] ? lengthColor : standardColor
                },
                color: certifiedDimension[0]  ? lengthColor : standardColor,
            },
            backgroundColor: certifiedDimension[0] ? backgroundColor : outOfBoundsColor,
        },
        widthTextArea: {
            "& .MuiInputBase-root.Mui-focused": {
                "& > fieldset": {
                    borderColor: certifiedDimension[1] ? widthColor : standardColor,
                },
                color: certifiedDimension[1] ? widthColor : standardColor,
            },
            backgroundColor: certifiedDimension[1] ? backgroundColor : outOfBoundsColor,
        },
        heightTextArea: {
            "& .MuiInputBase-root.Mui-focused": {
                "& > fieldset": {
                    borderColor: certifiedDimension[2] ? heightColor : standardColor,
                },
                color: certifiedDimension[2] ? heightColor : standardColor,
            },
            backgroundColor: certifiedDimension[2] ? backgroundColor : outOfBoundsColor,
        },
        volumeDensityWeightTextArea: {
            "& .MuiInputBase-root.Mui-focused": {
                "& > fieldset": {
                    borderColor: standardColor,
                },
                color: standardColor,
            },
            backgroundColor: backgroundColor,
        },
        lengthHelper: {
            color: certifiedDimension[0] ? lengthColor : standardColor,
            backgroundColor: helperBackgroundColor,
            fontSize: helperSize,
            margin: 0,
            textAlign: 'right',
        },
        widthHelper: {
            color: certifiedDimension[1] ? widthColor : standardColor,
            backgroundColor: helperBackgroundColor,
            fontSize: helperSize,
            margin: 0,
            textAlign: 'right',
        },
        heightHelper: {
            color: certifiedDimension[2] ? heightColor : standardColor,
            backgroundColor: helperBackgroundColor,
            fontSize: helperSize,
            margin: 0,
            textAlign: 'right',
        },
        volumeDensityWeightHelper: {
            color: standardColor,
            backgroundColor: helperBackgroundColor,
            fontSize: helperSize,
            margin: 0,
            textAlign: 'right',
        },
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
        >
            <Grid item md={11} xs={12}>
                <Grid container columns={{ md: 12 }} spacing={1}>
                    <DimensionField styling={styles.lengthTextArea} helperStyling={styles.lengthHelper} dimension={dimensionsAndWeight[0]} unit={units[0]} indicator={"L"}></DimensionField>
                    <DimensionField styling={styles.widthTextArea} helperStyling={styles.widthHelper} dimension={dimensionsAndWeight[1]} unit={units[0]} indicator={"W"}></DimensionField>
                    <DimensionField styling={styles.heightTextArea} helperStyling={styles.heightHelper} dimension={dimensionsAndWeight[2]} unit={units[0]} indicator={"H"}></DimensionField>
                    <DimensionField styling={styles.volumeDensityWeightTextArea} helperStyling={styles.volumeDensityWeightHelper} dimension={dimensionsAndWeight[3]} unit={units[2]} indicator={"V"}></DimensionField>
                    <DimensionField styling={styles.volumeDensityWeightTextArea} helperStyling={styles.volumeDensityWeightHelper} dimension={dimensionsAndWeight[5]} unit={units[3]} indicator={"D"}></DimensionField>
                    <DimensionField styling={styles.volumeDensityWeightTextArea} helperStyling={styles.volumeDensityWeightHelper} dimension={dimensionsAndWeight[4]} unit={units[1]} indicator={"W"}></DimensionField>
                </Grid>
            </Grid>
        </Grid>
    )
}