import { useState } from 'react';
import { Checkbox, Collapse, Grid, Typography } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import FlagIcon from '@mui/icons-material/Flag';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import _ from 'lodash';

export default function CustomFieldsCenter(props: any) {
    const { cargoResponse, setShowAuditDims, selectedScanIndex } = props;
    const [open, setOpen] = useState<boolean>(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const styles = {
        customFieldsText: {
            fontWeight: 500,
        },
        fileContainer: {
            border: '2px solid',
            borderColor: '#D3D3DE',
            mt: .5
        }
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mt: 1 }}
        >
            <Grid item md={10} xs={12} >
                <ListItemButton sx={{ backgroundColor: '#F8F8F9' }} onClick={handleClick} >
                    <ListItemText>
                        <Typography color="#636383" sx={styles.customFieldsText}>More Details</Typography>
                    </ListItemText>
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
            </Grid>
            <Grid item md={1}>
                <ListItemButton disabled={selectedScanIndex === 0 && cargoResponse ? false : true}
                    sx={{
                        backgroundColor: '#F8F8F9',
                        borderLeft: '1px solid',
                        borderBottom: open ? '1px solid' : "",
                        borderColor: "#D3D3DE"
                    }}
                    onClick={() => { setShowAuditDims(true) }}
                >
                    <ListItemText
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <FlagIcon sx={{ fontSize: 15 }} />
                    </ListItemText>
                </ListItemButton>
            </Grid>
            <Grid item md={11}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    {
                        (_.has(cargoResponse, 'customFields.CustomFields.Field')) ?
                            <Grid container>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                {cargoResponse['customFields']['CustomFields']['Field'].map((field: any) => (
                                                    <TableCell align="center" key={field['Name']}>{field['Name']}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                {cargoResponse['customFields']['CustomFields']['Field'].map((field: any) => {
                                                    if (field['Value'] === true || field['Value'] === false) {
                                                        return <TableCell align="center" key={field['Name']}><Checkbox disabled checked={field['Value']} /></TableCell>
                                                    }
                                                    return <TableCell align="center" key={field['Name']}>{field['Value'] ? field['Value'] : "-"}</TableCell>
                                                }
                                                )}
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            :
                            ''
                    }
                </Collapse>
            </Grid>
        </Grid>
    )
}