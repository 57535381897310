// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alice-carousel__dots-item.__active {
    background: #F48430 !important;
}

.alice-carousel__dots-item {
    background: #9a9edf !important;
}

.alice-carousel__dots {
    padding: 0;
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Control/Details/CenterControlComponents/scanPictureComponents/carousel.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;AAClC;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,UAAU;IACV,SAAS;AACb","sourcesContent":[".alice-carousel__dots-item.__active {\n    background: #F48430 !important;\n}\n\n.alice-carousel__dots-item {\n    background: #9a9edf !important;\n}\n\n.alice-carousel__dots {\n    padding: 0;\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
