import { Grid, ListItem, ListItemText, Typography, Box, ListItemButton } from "@mui/material";
import Barcode_Mini_Icon from '../../../../Assets/Barcode_Mini_Icon.png'
import { Launch } from "@mui/icons-material";
import _ from 'lodash';

export default function BarcodeCenterControl(props: any) {
    const { cargoResponse } = props;

    const styles = {
        barcodeField: {
            mt: 2,
            mb: 2
        },
        barcodeImage: {
            display: "inline",
            pr: 1,
            pl: 0.5
        },
        detailsButton: {
            color: "#FFFFFF",
            backgroundColor: '#262D6D',
            '&:hover': {
                backgroundColor: '#191E48',
                borderColor: '#FFFFFF'
            },
            '&:disabled': {
                color: "#FFFFFF",
                backgroundColor: '#D3D3DE'
            },
            '&.Mui-disabled': {
                color: "#FFFFFF",
                backgroundColor: '#D3D3DE'
            },
        },
    }

    return (
        <Grid item md={12} display={{ xs: 'none', md: 'block' }} sx={styles.barcodeField}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item md={10} xs={12} >
                    <ListItem sx={{ backgroundColor: '#F8F8F9', }}>
                        {cargoResponse ?
                            <ListItemText>
                                <Box
                                    component="img"
                                    sx={styles.barcodeImage}
                                    alt="Barcode:"
                                    src={Barcode_Mini_Icon}
                                />:&nbsp;
                                <Typography display="inline" sx={{ fontWeight: 500, wordWrap: "break-word" }}>{cargoResponse ? cargoResponse.barcode : ""}</Typography>
                            </ListItemText>
                            :
                            <ListItemText style={{ display: 'flex', justifyContent: 'center' }}>
                                <Typography display="inline" sx={{ fontWeight: 500, }}>Awaiting scan</Typography>
                            </ListItemText>
                        }
                    </ListItem>
                </Grid>
                <Grid item md={1}>
                    <ListItemButton disabled={cargoResponse && _.get(cargoResponse, 'cargoID', undefined) ? false : true}
                        sx={{
                            backgroundColor: '#F8F8F9',
                            borderLeft: '1px solid',
                            borderColor: "#D3D3DE"
                        }}
                        href={`https://spectre-licensing.com/Cargo/Details/${cargoResponse ? cargoResponse.cargoID : ""}`} target="_blank"
                    >
                        <ListItemText
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Launch sx={{ fontSize: 15 }} />
                        </ListItemText>
                    </ListItemButton>
                </Grid>
            </Grid>
        </Grid>
    )
}