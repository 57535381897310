import React, { useEffect, useState } from 'react'
import { Box, Card, CardContent, Typography, Button, Grid, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { Machine, OfflineMachine } from '../../../ts/Connections';
import Parcel_Icon_Online from '../../../Assets/Parcel_Icon_Online.png';
import Parcel_Icon_Offline from '../../../Assets/Parcel_Icon_Offline.png'
import Pallet_Icon_Online from '../../../Assets/Pallet_Icon_Online.png'
import Pallet_Icon_Offline from '../../../Assets/Pallet_Icon_Offline.png'
import Item from '@mui/material/ListItem'
import Zoom from '@mui/material/Zoom';
import CircularProgress from '@mui/material/CircularProgress';
import { versionCompare } from '../../../ts/Common';
import { styled, keyframes } from '@mui/system';
const UPDATE_RECOMMENDED = process.env.REACT_APP_UPDATE_RECOMMENDED || "1"
const UPDATE_REQUIRED = process.env.REACT_APP_UPDATE_REQUIRED || "1"

export default function MatcmakingCards(props: any) {
    const { onlineMachines, setSelectedMachine, activeCard, setOnlineMachines, get_preferred_connection, cookies, offlineMachines, setOfflineMachines } = props
    const [failedConnections, setFailedConnections] = useState<Record<number, boolean>>({});
    const [refreshSpinner, setRefreshSpinner] = useState<Record<number, boolean>>({});

    const styles = {
        gridContainer: {
            justifyContent: {
                xs: 'center',
                md: 'left'
            },
            mb: {
                xs: 2,
                md: 0,
            }
        },
        grid: {
            display: "inline-flex",
            height: 250,
            mt: 2
        },
        cardStyles: {
            width: 400,
            height: 200,
            fill: (theme: any) => theme.palette.common.white,
            stroke: (theme: any) => theme.palette.divider,
            strokeWidth: 1,
        },
        imageGrid: {
            margin: {
                md: 'auto',
                xs: '15px'
            }
        },
        imageBox: {
            height: 64
        },
        machineNameTypography: {
            fontWeight: 700,
            fontSize: 20,
            color: "#262D6D",
        },
        macAddressTypography: {
            fontWeight: 500,
            fontSize: 16,
            color: '#636383',
        },
        machineLocationTypography: {
            float: 'left',
            align: 'left',
            fontWeight: 500,
            fontSize: 16,
            color: '#636383'
        },
        failReconnectTypography: {
            float: 'left',
            align: 'left',
            fontWeight: 500,
            fontSize: 16,
            color: '#F48430'
        },
        button: {
            size: "large",
            float: 'left',
        },
        buttonTypography: {
            fontWeight: 700,
            fontSize: 16,
            color: "#FFFFFF"
        },
        inactiveCard: {
            backgroundColor: '#E2E2E9',
            button: {
                backgroundColor: '#262D6D'
            }
        },
        activeCard: {
        },
        icon: {
            parcel_online: Parcel_Icon_Online,
            parcel_offline: Parcel_Icon_Offline,
            pallet_online: Pallet_Icon_Online,
            pallet_offline: Pallet_Icon_Offline
        }
    }

    const blink = keyframes`
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
    `;

    const BlinkingDot = styled('span')(({ theme }) => ({
        display: 'inline-block',
        width: '10px',
        height: '10px',
        // backgroundColor: theme.palette.secondary.main,
        borderRadius: '50%',
        marginRight: '8px',
        animation: `${blink} 1.5s infinite ease-in-out`,
    }));

    return (
        <Grid container
            direction="row"
            alignItems="center"
            sx={styles.gridContainer}
        >
            {
                onlineMachines.map((machine: Machine, index: number) => {
                    const connectionFailed = failedConnections[index];
                    const refreshSpinState = refreshSpinner[index];
                    let connectButton;
                    let machineIcon;
                    if (machine.scanMode === 2 || machine.scanMode === 4) {
                        machineIcon = styles.icon.parcel_online
                    } else {
                        machineIcon = styles.icon.pallet_online
                    }
                    if (activeCard === true && (versionCompare(machine.version, UPDATE_REQUIRED, {}) > 0)) {
                        connectButton = <Button color="secondary" sx={styles.button} variant="contained" onClick={() => {
                            setSelectedMachine(machine)
                        }}>
                            <Typography sx={styles.buttonTypography}>+ Connect</Typography>
                        </Button>
                    } else if (activeCard === true && versionCompare(machine.version, UPDATE_REQUIRED, {}) <= 0) {
                        connectButton = <Button color="secondary" disabled sx={styles.button} variant="contained" onClick={() => {
                            setSelectedMachine(machine)
                        }}>
                            <Typography sx={styles.buttonTypography}>Out of Date</Typography>
                        </Button>
                    } else {
                        let disabledState = false;
                        let buttonText = "Refresh"
                        connectButton = <Button sx={styles.button} disabled={disabledState} variant="contained" onClick={() => {
                            setRefreshSpinner((prev) => ({ ...prev, [index]: true }));
                            get_preferred_connection(machine, cookies.user64)
                                .then((data: any) => {
                                    if (data == null) {
                                        setFailedConnections((prev) => ({ ...prev, [index]: true }));
                                        setRefreshSpinner((prev) => ({ ...prev, [index]: false }));
                                        return
                                    };
                                    setRefreshSpinner((prev) => ({ ...prev, [index]: false }));
                                    setFailedConnections((prev) => ({}));
                                    let machine_object = new Machine(data, machine.machineName, machine.localIP, machine.publicIP, machine.matchmakingID, machine.location, machine.macAddress, machine.scanMode, machine.certification, machine.version)
                                    setOnlineMachines((old: any) => { return [...old, machine_object] })
                                    let newOffLineMachines = offlineMachines.filter((offlineMachine: OfflineMachine) => {
                                        return offlineMachine !== machine
                                    })
                                    setOfflineMachines(newOffLineMachines)
                                })
                        }}>
                            <Typography sx={styles.buttonTypography}>{buttonText}</Typography>

                            {refreshSpinState ? <CircularProgress size={14} sx={{ ml: 2, color: "#FFFFFF" }} /> : ""}

                        </Button>
                        if (machine.scanMode === 2 || machine.scanMode === 4) {
                            machineIcon = styles.icon.parcel_offline
                        } else {
                            machineIcon = styles.icon.pallet_offline
                        }
                    }

                    return (
                        <Grid container key={index} item xs={11} sm={12} md={6} lg={4}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={styles.grid}
                        >
                            <Zoom in={true} style={{ transitionDelay: `${index * 350}ms` }}>
                                <Box sx={styles.cardStyles}>
                                    <Card>
                                        <CardContent sx={activeCard ? styles.activeCard : styles.inactiveCard}>
                                            <Grid container>
                                                <Grid item md={3} sx={styles.imageGrid}>
                                                    <Box
                                                        component="img"
                                                        sx={styles.imageBox}
                                                        alt="Parcel logo."
                                                        src={machineIcon}
                                                    />
                                                </Grid>
                                                <Grid item md={9}>
                                                    <Stack direction='column' justifyContent='center'>
                                                        <Item>
                                                            <Typography sx={styles.machineNameTypography} align="left" mt={0.5}>
                                                                {machine.machineName}
                                                            </Typography>
                                                        </Item>
                                                        <Item>
                                                            <Typography sx={styles.macAddressTypography} align="left">
                                                                ID: {machine.macAddress}
                                                            </Typography>
                                                        </Item>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                            <Grid container p={3}>
                                                <Grid item md={12}>
                                                    <Typography component='div' sx={styles.machineLocationTypography}>
                                                        Spectre Location: {machine.location}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container
                                                direction="row"
                                                justifyContent="space-evenly"
                                                alignItems="center"
                                            >
                                                <Grid item md={6} pl={2}>
                                                    {activeCard ? (
                                                        <Link to={`/matchmaking/control/${machine.machineName}`}>
                                                            {connectButton}
                                                        </Link>
                                                    ) : (
                                                        connectButton
                                                    )}
                                                </Grid>
                                                <Grid item md={6}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    {
                                                        activeCard && (versionCompare(machine.version, UPDATE_RECOMMENDED, {}) <= 0) && (versionCompare(machine.version, UPDATE_REQUIRED, {}) > 0) ? 
                                                            <Typography sx={{ fontSize: 15, fontWeight: 550, float: 'right', color: '#F7914B' }}>
                                                                <BlinkingDot sx={{ backgroundColor: '#F7914B' }}/>Update recommended
                                                            </Typography>
                                                            :
                                                            ""
                                                    }
                                                    {
                                                        activeCard && (versionCompare(machine.version, UPDATE_REQUIRED, {}) <= 0) ?
                                                            <Typography sx={{ fontSize: 15, fontWeight: 550, float: 'right', color: '#ff0f0f' }}>
                                                                <BlinkingDot sx={{ backgroundColor: '#ff0f0f'}} />Update required
                                                            </Typography>
                                                            :
                                                            ""
                                                    }
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <Grid item md={12}>
                                        {connectionFailed ? (<Typography sx={styles.failReconnectTypography}>Error: Failed to connect to {machine.machineName}</Typography>) : ""}
                                    </Grid>
                                </Box>

                            </Zoom>
                        </Grid>
                    );
                })
            }
        </Grid>
    )
}