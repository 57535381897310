import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, Button, Box, MenuItem, Menu, Typography, IconButton, Tooltip, Avatar, Container, Grid, Stack, Divider, useTheme, useMediaQuery } from "@mui/material"
import { Link } from 'react-router-dom';
import Link_Icon_White from '../Assets/Link_Icon_White.png'
// import Parcel_Icon_Online from '../Assets/Parcel_Icon_Online.png';
// import Pallet_Icon_Online from '../Assets/Pallet_Icon_Online.png'
import Settings_Icon from '../Assets/Settings_Icon.png'
import Support_Icon from '../Assets/Support_Icon.png'
import Calibrate_Icon from '../Assets/Calibrate_1_Icon.png'
import Info_Icon from '../Assets/Info_Icon.png'
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useLocation } from 'react-router-dom';

const pages = ["Matchmaking"]
const DEV_MODE = process.env.REACT_APP_DEV_ENVIRONMENT || false;
const DOMAIN = process.env.REACT_APP_DOMAIN || '.spectre-licensing.com'

export const Navbar = (props: any) => {
    const { setIsLoggedIn, user, removeCookie, window, setShowCalibration, setShowMachineSettings, setShowSupport, setShowCertification, selectedMachine } = props
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [mobileOpen, setMobileOpen] = useState<boolean>(false);
    // const [machineIcon, setMachineIcon] = useState<any>(Pallet_Icon_Online);

    const navigate = useNavigate();

    // useEffect(() => {
    //     selectedMachine.scanMode === 2 ? setMachineIcon(Parcel_Icon_Online) : setMachineIcon(Pallet_Icon_Online)
    // }, [selectedMachine])

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const location = useLocation();
    const isControlRoute = location.pathname.startsWith('/matchmaking/control/');

    const drawerWidth = 240;

    //https://mui.com/material-ui/react-app-bar/#fixed-placement;
    const container = window !== undefined ? () => window().document.body : undefined;

    // for dev_mode and displaying current window size
    const theme = useTheme();

    const isSm = useMediaQuery(theme.breakpoints.only('sm'));
    const isMd = useMediaQuery(theme.breakpoints.only('md'));
    const isLg = useMediaQuery(theme.breakpoints.only('lg'));
    const isXl = useMediaQuery(theme.breakpoints.only('xl'));

    let breakpointText = 'xs';

    if (isSm) {
        breakpointText = 'sm';
    } else if (isMd) {
        breakpointText = 'md';
    } else if (isLg) {
        breakpointText = 'lg';
    } else if (isXl) {
        breakpointText = 'xl';
    }

    const styles = {
        
        NavtabButton: {
            height: 64,
            width: 125,
            color: "#1A1B23",
            boxShadow: 0,
            backgroundColor: "#F5F5F8",
            '&:hover': {
                backgroundColor: '#D3D3DE',
            },
            '&:select': {
                backgroundColor: '#D3D3DE',
            },
            borderRadius: 0,
            borderRight: '2px solid',
            borderLeft: {
                xs: '2px solid',
                md: 'none'
            },
            borderColor: {
                xs: '#D3D3DE',
                md: 'none'
            },
        },
        buttonText: {
            fontSize: 15,
            fontWeight: 500,
            color: 'white'
        },
        Navtabselected: {
            height: 64,
            width: 125,
            borderRadius: 0,
            color: "error.main",
            backgroundColor: "secondary.main",
        },
        Navbar: {
            borderRadius: 0,
            elevation: 0,
        },
        Logoff: {
            float: 'right'
        },
        devMode: {
            color: 'black',
            fontWeight: 500,
            backgroundColor: "#F48430",
            text: breakpointText
        },
        drawer: {
            backgroundColor: '#00004B',
        },
        menuListItem: {
            textAlign: 'center',
            color: '#FFFFFF',
            '&:hover': {
                backgroundColor: '#384085',
            },
        },
        divider: {
            backgroundColor: '#FFFFFF'
        },
        buttonIcons: {
            height: 22,
        },
        buttonIconsNavBar: {
            height: 15,
            mr: 1
        },
        navButton: {
            color: "#FFFFFF",
            backgroundColor: '#2B3167',
            '&:hover': {
                backgroundColor: '#384085',
            },
        },
        imageBox: {
            height: 40,
        },
        // icon: {
        //     parcel_online: Parcel_Icon_Online,
        //     pallet_online: Pallet_Icon_Online,
        //     empty: ""
        // },
        mySpectresLarge: {
            display: {
                lg: 'block',
                xs: 'none'
            },
            mr: 1
        }
    }

    const controlButtons = (
        <React.Fragment>
            <ListItem disablePadding>
                <ListItemButton sx={styles.menuListItem} component={Link} to={`/${pages[0]}`}>
                    <ListItemText primary={"My Spectres"} />
                </ListItemButton>
            </ListItem>
            <Divider sx={styles.divider}></Divider>
            <ListItem sx={{
                textAlign: 'center',
                color: '#FFFFFF',
            }}>
                <ListItemText primary={selectedMachine ? selectedMachine.machineName : ""} />
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton sx={styles.menuListItem} onClick={() => { setShowCalibration(true) }}>
                    <ListItemIcon>
                        <Box
                            component="img"
                            sx={styles.buttonIcons}
                            alt="Calibrate icon"
                            src={Calibrate_Icon}
                        />
                    </ListItemIcon>
                    <ListItemText primary={"Calibrate"} />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton sx={styles.menuListItem} onClick={() => { setShowMachineSettings(true) }}>
                    <ListItemIcon>
                        <Box
                            component="img"
                            sx={styles.buttonIcons}
                            alt="Settings icon"
                            src={Settings_Icon}
                        />
                    </ListItemIcon>
                    <ListItemText primary={"Machine Settings"} />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton sx={styles.menuListItem} onClick={() => { setShowSupport(true) }}>
                    <ListItemIcon>
                        <Box
                            component="img"
                            sx={styles.buttonIcons}
                            alt="Support icon"
                            src={Support_Icon}
                        />
                    </ListItemIcon>
                    <ListItemText primary={"Open Support"} />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton sx={styles.menuListItem} onClick={() => { setShowCertification(true) }}>
                    <ListItemIcon>
                        <Box
                            component="img"
                            sx={styles.buttonIcons}
                            alt="Info icon"
                            src={Info_Icon}
                        />
                    </ListItemIcon>
                    <ListItemText primary={"Certification"} />
                </ListItemButton>
            </ListItem>
        </React.Fragment>
    )

    const userIcon = (
        <React.Fragment >
            <Tooltip title="Open user settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 1 }}>
                    <Avatar alt={user.toUpperCase()} src="/static/images/avatar/2.jpg" />
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <MenuItem onClick={() => {
                    removeCookie('user64', { path: '/', domain: DOMAIN })
                    setIsLoggedIn(false)
                    navigate('/login')
                }}>
                    <Typography sx={{ fontWeight: 500 }}>Log Off</Typography>
                </MenuItem>
            </Menu>
        </React.Fragment>
    )

    const drawer = (
        <Box sx={{ textAlign: 'center' }}>
            {userIcon}
            <List onClick={handleDrawerToggle}>
                {isControlRoute ? controlButtons : ''}
            </List>
        </Box>
    );

    useEffect(() => {

    })

    return (
        <>
            <AppBar elevation={0} position="fixed">
                <Container maxWidth={false}>
                    <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
                        <Grid container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center">
                            <Grid container item md={6} sm={6} xs={6}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center">
                                <Box
                                    component="img"
                                    sx={{
                                        height: 50,
                                        // p: 1
                                    }}
                                    alt="Your logo."
                                    src={Link_Icon_White}
                                />
                                {DEV_MODE ? <Typography sx={styles.devMode}>dev_mode | size: {styles.devMode.text}</Typography> : <></>}
                                {
                                    selectedMachine
                                        ?
                                        <Button className="Nav-Bar-Button" component={Link} to={'/Matchmaking'} sx={styles.mySpectresLarge}>
                                            <Typography sx={styles.buttonText}>My Spectres</Typography>
                                        </Button>
                                        :
                                        ""
                                }
                            </Grid>
                            <Grid container item md={6}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{
                                    display: {
                                        lg: 'flex',
                                        xs: 'none'
                                    }
                                }}
                            >
                                <Grid container item md={2}
                                    sx={{
                                        display: {
                                            lg: 'block',
                                            xs: 'none'
                                        }
                                    }}>
                                    <Stack alignItems="center" direction="row" gap={1}>
                                        {selectedMachine ?
                                            <>
                                                <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'white' }} />
                                                <Typography sx={styles.buttonText}> {selectedMachine.machineName} </Typography>
                                            </>
                                            :
                                            <></>
                                        }
                                    </Stack>
                                </Grid>
                                <Grid container item md={10}
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    sx={{
                                        display: {
                                            lg: 'flex',
                                            xs: 'none'
                                        },
                                    }}
                                    gap={0.5}>
                                    {selectedMachine ?
                                        <>
                                            <Button className='Nav-Bar-Button' onClick={() => { setShowCalibration(true) }}>
                                                <Box
                                                    component="img"
                                                    sx={styles.buttonIconsNavBar}
                                                    alt="Calibrate icon"
                                                    src={Calibrate_Icon}
                                                />Calibrate</Button>
                                            <Button className='Nav-Bar-Button' onClick={() => { setShowSupport(true) }}>
                                                <Box
                                                    component="img"
                                                    sx={styles.buttonIconsNavBar}
                                                    alt="Support icon"
                                                    src={Support_Icon}
                                                />Support</Button>
                                            <Button className='Nav-Bar-Button' onClick={() => { setShowMachineSettings(true) }}>
                                                <Box
                                                    component="img"
                                                    sx={styles.buttonIconsNavBar}
                                                    alt="Settings icon"
                                                    src={Settings_Icon}
                                                />Settings</Button>
                                            <Button className='Nav-Bar-Button' onClick={() => { setShowCertification(true) }}>
                                                <Box
                                                    component="img"
                                                    sx={styles.buttonIconsNavBar}
                                                    alt="Info icon"
                                                    src={Info_Icon}
                                                />Machine Info</Button>
                                        </>
                                        : ""}
                                    <Box sx={{ flexGrow: 0 }}>
                                        {userIcon}
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container item
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                sx={{
                                    display: {
                                        lg: 'none',
                                        xs: 'flex'
                                    },
                                }}
                                sm={6} xs={6}>
                                <Grid item>
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        edge="end"
                                        onClick={handleDrawerToggle}
                                        sx={{ color: "#FFFFFF" }}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </Container>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { md: 'block', lg: 'none' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box', width: drawerWidth, height: '100%',
                            backgroundColor: styles.drawer.backgroundColor
                        },
                        zIndex: 1250
                    }}
                    anchor='right'
                >
                    {drawer}
                </Drawer>
            </nav>
        </>
    )
}