import { Button, Grid } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';

export default function UploadAndExportButton(props: any) {
    const { machineScan, machineActive, disableUploadExportButton } = props

    const styles = {
        CircularProgress: {
            mr: 1,
            color: "#FFFFFF"
        }
    }

    if (!machineActive) {
        return (
            <Grid item md={11} xs={11} pt={2}>
                <Button className="Upload-Export-Blue" disabled={disableUploadExportButton} fullWidth onClick={() => { machineScan("uploadAndExport") }}>Upload And Export</Button>
            </Grid>
        )
    } else {
        return (
            <Grid item md={11} xs={11} pt={2}>
                <Button className="Upload-Export-Blue" disabled fullWidth onClick={() => { machineScan("uploadAndExport") }}><CircularProgress sx={styles.CircularProgress} size={25} />Upload And Export</Button>
            </Grid>
        )
    }
}