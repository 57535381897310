import { Button, Grid } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';

export default function ExportButton(props: any) {
    const { machineScan, machineActive, disableExportButton } = props

    const styles = {
        CircularProgress: {
            mr: 1,
            color: "#FFFFFF"
        }
    }
    if (!machineActive) {
        return (
            <Grid item md={5} xs={5} pt={2} pb={3}>
                <Button className="Upload-Export-Blue" disabled={disableExportButton} fullWidth onClick={() => { machineScan("export") }}>Export</Button>
            </Grid>
        )
    } else {
        return (
            <Grid item md={5} xs={5} pt={2} pb={3}>
                <Button className="Upload-Export-Blue" disabled fullWidth onClick={() => { machineScan("export") }}><CircularProgress sx={styles.CircularProgress} size={25} />In Progress...</Button>
            </Grid>
        )
    }
}