import { Box, Grid, TextField, CircularProgress } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment';
import { useState, useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import _ from 'lodash';

export default function BarcodeField(props: any) {
    const { barcodeValue, setBarcodeValue, machineActive, settings, showMachineSettings, settingsLoaded, machineScan, machineCommand, scanButtonPressed, setScanButtonPressed } = props
    const [placeHolderText, setPlaceHolderText] = useState<string>('')
    const [scanOnEnter, setScanOnEnter] = useState<boolean>(false);

    useEffect(() => {
        if (_.get(settings, 'Scanner.AutoGenerateBarcode.Enabled')) {
            settings.Scanner.AutoGenerateBarcode.Enabled.value === true ? setPlaceHolderText('Barcode Generation On') : setPlaceHolderText('')
        } else {
            setPlaceHolderText('')
        }
        if (settingsLoaded) {
            setScanOnEnter(settings.Scanner.AutoBarcodeEnabled.value)
        }
    }, [settings, showMachineSettings, settingsLoaded])

    const styles = {
        barcodeComponent: {
            backgroundColor: '#FFFFFF',
            mt: 1
        },
        barcodeTextArea: {
            color: '#4B4E65',
            backgroundColor: '#EAEAF0',
        },
        barcodeAdornment: {
            fontSize: 20
        }
    }

    const updateBarcodeSubmitValue = function (element: any) {
        setBarcodeValue(element)
    }

    const onKeyDownHandler = function (event: any) {
        if (scanOnEnter && !machineActive && event.keyCode === 13) {
            setScanButtonPressed(true);
        }
    }

    if (!settingsLoaded) {
        return (
            <CircularProgress sx={{ color: "#F48430", mt: 2 }} />
        )
    } else {
        return (
            <Box>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                >
                    <Grid item md={11} xs={11} sx={styles.barcodeComponent}>
                        {machineActive && settings.Scanner.AutoGenerateBarcode.Enabled.value && barcodeValue.length === 0 && scanButtonPressed ? <LinearProgress sx={{
                            backgroundColor: '#262D6D',
                            '& .MuiLinearProgress-bar': {
                                backgroundColor: "#F48430",
                            }
                        }}></LinearProgress> : ''}
                        <TextField id="barcode-field" variant="outlined" onKeyDown={onKeyDownHandler} fullWidth sx={styles.barcodeTextArea} placeholder={placeHolderText} value={barcodeValue} onChange={(element) => { updateBarcodeSubmitValue(element.target.value) }}
                            InputProps={{
                                startAdornment: <InputAdornment sx={styles.barcodeAdornment} position="start">Barcode | </InputAdornment>
                            }}
                        />
                        {machineActive && settings.Scanner.AutoGenerateBarcode.Enabled.value && barcodeValue.length === 0 && scanButtonPressed ? <LinearProgress sx={{
                            backgroundColor: '#262D6D',
                            '& .MuiLinearProgress-bar': {
                                backgroundColor: "#F48430"
                            }
                        }}></LinearProgress> : ''}
                    </Grid>
                </Grid>
            </Box>
        )
    }
}