import { useEffect, useState } from 'react';
import { Grid, Box, CircularProgress } from '@mui/material';
import CustomFieldTextBox from './customFieldComponents/CustomFieldTextBox';
import CustomFieldNumberBox from './customFieldComponents/CustomFieldNumberBox';
import CustomFieldCheckBox from './customFieldComponents/CustomFieldCheckBox';
import CustomFieldDropDown from './customFieldComponents/CustomFieldDropDown';
import CustomFieldAutoIncrement from './customFieldComponents/CustomFieldAutoIncrement';

export default function CustomFields(props: any) {
    const { customFields, customFieldDropDownValues, customFieldSubmitValues, setCustomFieldSubmitValues,
        setCustomFieldSubmitValuesEmpty, customFieldsLoaded, scanButtonPressed, cargoResponses, barcodeValue, checkCustomFieldsReady } = props
    const [loaded, setLoaded] = useState<boolean>(false)
    const [loadedTwo, setLoadedTwo] = useState<boolean>(false)
    const [incrementer, setIncrementer] = useState<number>(0);

    const updateCustomFieldSubmitValues = function (index: any, element: any) {
        let customFieldsCopy = JSON.parse(JSON.stringify(customFieldSubmitValues))
        customFieldsCopy['CustomFields']['Field'][index]['Value'] = element
        customFieldsCopy['CustomFields']['Field'][index]['Ready'] = true
        setCustomFieldSubmitValues(customFieldsCopy);
    }

    useEffect(() => {
        if (loaded) {
            checkCustomFieldsReady();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customFieldSubmitValues])

    useEffect(() => {
        if (customFieldsLoaded) {
            let customFieldsCopy = [...customFields]
            let customFieldsCopyTwo = [...customFields]
            setCustomFieldSubmitValues(mapOutCustomFieldsForm(customFieldsCopy));
            setCustomFieldSubmitValuesEmpty(mapOutCustomFieldsForm(customFieldsCopyTwo));
            setLoadedTwo(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customFieldsLoaded])

    useEffect(() => {
        if (loadedTwo) {
            setLoaded(true)
            checkCustomFieldsReady()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadedTwo])

    // CustomField Helpers
    const mapOutCustomFieldsForm = function (customFields: any) {
        var customFieldsForm = { "CustomFields": { "Field": [] } }
        customFieldsForm["CustomFields"]["Field"] = customFields.map((customField: any) => {
            if (customField["fieldType"] === 2) {
                return {
                    "Name": customField["name"],
                    "FieldType": customField["fieldType"],
                    "Value": false,
                    "Id": customField["customFieldID"],
                    "Ready": true
                }
            }
            if (customField["fieldType"] === 4) {
                return {
                    "Name": customField["name"],
                    "FieldType": customField["fieldType"],
                    "Value": incrementer,
                    "Id": customField["customFieldID"],
                    "Ready": false
                }
            }
            else {
                return {
                    "Name": customField["name"],
                    "FieldType": customField["fieldType"],
                    "Value": "",
                    "Id": customField["customFieldID"],
                    "Required": customField["required"],
                    "Ready": true
                }
            }
        });
        return customFieldsForm;
    }

    const styles = {
        customFieldsBox: {
            backgroundColor: '#EAEAF0',
            borderBottom: '2px solid',
            borderColor: '#D3D3DE',
            pb: 1
        }
    }

    if (!loaded) {
        return (
            <CircularProgress sx={{ color: "#F48430", mt: 2 }} />
        )
    } else {
        return (
            <Box sx={styles.customFieldsBox} style={{ paddingTop: 68 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                >
                    {
                        customFields.map((customField: any, index: any) => {
                            if (customField.fieldType === 0) {
                                return <CustomFieldTextBox key={index} index={index} customField={customField} updateCustomFieldSubmitValues={updateCustomFieldSubmitValues} customFieldSubmitValues={customFieldSubmitValues}></CustomFieldTextBox>
                            }
                            else if (customField.fieldType === 1) {
                                return <CustomFieldNumberBox key={index} index={index} customField={customField} updateCustomFieldSubmitValues={updateCustomFieldSubmitValues} customFieldSubmitValues={customFieldSubmitValues}></CustomFieldNumberBox>
                            }
                            else if (customField.fieldType === 2) {
                                return <CustomFieldCheckBox key={index} index={index} customField={customField} updateCustomFieldSubmitValues={updateCustomFieldSubmitValues} customFieldSubmitValues={customFieldSubmitValues}></CustomFieldCheckBox>
                            }
                            else if (customField.fieldType === 3) {
                                return <CustomFieldDropDown key={index} index={index} customField={customField} customFieldDropDownValues={customFieldDropDownValues} updateCustomFieldSubmitValues={updateCustomFieldSubmitValues} customFieldSubmitValues={customFieldSubmitValues}></CustomFieldDropDown>
                            }
                            else if (customField.fieldType === 4) {
                                // needs auto increment functionality added.
                                return <CustomFieldAutoIncrement key={index} index={index} customField={customField} updateCustomFieldSubmitValues={updateCustomFieldSubmitValues} scanButtonPressed={scanButtonPressed} cargoResponses={cargoResponses} barcodeValue={barcodeValue}
                                    incrementer={incrementer} setIncrementer={setIncrementer}></CustomFieldAutoIncrement>
                            }
                            else {
                                return <Grid key={index} item md={12}>
                                    <div key={index}> Custom Field Unknown </div>
                                </Grid>
                            }
                        })
                    }
                </Grid>
            </Box>
        )
    }

}