import { useState } from 'react';
import { Collapse, Grid, ListItem, Typography } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

export default function ErrorDetails(props: any) {
    const { cargoResponse } = props;
    const [open, setOpen] = useState<boolean>(false);

    const handleClick = () => {
        setOpen(!open);
    };

    if (cargoResponse && ((cargoResponse.dimensionCode !== "0" && cargoResponse.dimensionCodeDescription !== "") || (cargoResponse.uploadCode !== "0" && cargoResponse.uploadCodeDescription !== "") || (cargoResponse.exportCode !== "0" && cargoResponse.exportCodeDescription !== ""))) {
        return (
            <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                sx={{ mt: 1 }}
            >
                <Grid item md={11} xs={12} >
                    <ListItemButton sx={{ backgroundColor: '#F8F8F9' }} onClick={handleClick} >
                        <ListItemText>
                            <Typography color="#636383" sx={{ fontWeight: 500}}>Error Details &#9888;</Typography>
                        </ListItemText>
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <ListItemText sx={{ backgroundColor: '#F8F8F9' }}
                        >
                            {
                                cargoResponse.dimensionCode !== "0" && cargoResponse.dimensionCodeDescription !== "" ?
                                <ListItem sx={{ backgroundColor: '#F8F8F9', }}>
                                        <ListItemText>
                                            <Typography display="inline" sx={{ fontWeight: 500, wordWrap: "break-word" }}>Dimension: {cargoResponse.dimensionCodeDescription}</Typography>
                                        </ListItemText>
                                </ListItem>
                                :
                                ""
                            }
                            {
                                cargoResponse.uploadCode !== "0" && cargoResponse.uploadCodeDescription !== "" ?
                                    <ListItem sx={{ backgroundColor: '#F8F8F9', }}>
                                        <ListItemText>
                                            <Typography display="inline" sx={{ fontWeight: 500, wordWrap: "break-word" }}>Upload: {cargoResponse.uploadCodeDescription}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    :
                                    ""
                            }
                            {
                                cargoResponse.exportCode !== "0" && cargoResponse.exportCodeDescription !== "" ?
                                    <ListItem sx={{ backgroundColor: '#F8F8F9', }}>
                                        <ListItemText>
                                            <Typography display="inline" sx={{ fontWeight: 500, wordWrap: "break-word" }}>Export: {cargoResponse.exportCodeDescription}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    :
                                    ""
                            }
                        </ListItemText>
                    </Collapse>
                </Grid>
            </Grid>
        )
    } else {
        return <></>
    }
}