import { useState, useEffect } from 'react';
import { Grid, CircularProgress } from '@mui/material'
import ScanButton from './scanButtonComponents/ScanButton';
import UploadButton from './scanButtonComponents/UploadButton';
import ExportButton from './scanButtonComponents/ExportButton';
import UploadAndExportButton from './scanButtonComponents/UploadAndExport';
import SlideButton from './scanButtonComponents/SlideButton';

export default function RemoteControlButtons(props: any) {
    const { machineScan, machineActive, disableScanButton, setDisableScanButton, cargoResponses, settings, settingsLoaded, showMachineSettings, machineCommand, setMachineCommand, setScanButtonPressed } = props
    const [scanButtonText, setScanButtonText] = useState<string>("Scan");
    const [disableUploadExportButton, setDisableUploadExportButton] = useState<boolean>(true);
    const [disableUploadButton, setDisableUploadButton] = useState<boolean>(true);
    const [uploadButtonText, setUploadButtonText] = useState<string>("Upload");
    const [disableExportButton, setDisableExportButton] = useState<boolean>(true);
    const [scanAndUpload, setScanAndUpload] = useState<boolean>(false);
    const [uploadAndExport, setUploadAndExport] = useState<boolean>(false);

    useEffect(() => {
        if (settingsLoaded) {
            setScanAndUpload(settings.Scanner.AutoSaveEnabled.value)
            setUploadAndExport(settings.Scanner.AutoExportEnabled.value)
        }
    }, [settings, showMachineSettings, settingsLoaded])

    useEffect(() => {
        if (cargoResponses.length > 0) {
            setDisableUploadExportButton(false);
            setDisableUploadButton(false);
            if (!settings.Export.Url.value) {
                setDisableScanButton(true)
                setDisableExportButton(true);
                setDisableUploadExportButton(true);
            } else {
                setDisableScanButton(false);
                setDisableUploadExportButton(false);
                setDisableExportButton(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cargoResponses, showMachineSettings])

    useEffect(() => {
        if (cargoResponses.length > 0 && cargoResponses[0].uploaded === true) {
            setDisableUploadButton(true);
            setUploadButtonText("Uploaded")
            if (settings.Export.Url.value) {
                setDisableExportButton(false);
            }
        } else {
            setUploadButtonText("Upload")
            setDisableExportButton(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [machineScan])

    useEffect(() => {
        if (settingsLoaded) {
            if (scanAndUpload === false && uploadAndExport === false) {
                setScanButtonText("Scan")
                setMachineCommand("dimension")
            }
            else if (scanAndUpload === false && uploadAndExport === true) {
                setScanButtonText("Scan")
                setMachineCommand("dimension")
            }
            else if (scanAndUpload === true && uploadAndExport === true) {
                setScanButtonText("Scan, Upload, & Export")
                setMachineCommand("scanUploadAndExport")
            }
            else if (scanAndUpload === true && uploadAndExport === false) {
                setScanButtonText("Scan & Upload")
                setMachineCommand("dimensionAndUpload")
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scanAndUpload, uploadAndExport, setScanButtonText, settings, settingsLoaded])

    let scanButtons;

    if (scanAndUpload === false && uploadAndExport === false) {
        scanButtons = <Grid item container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            md={12}>
            <ScanButton machineScan={machineScan} scanButtonText={scanButtonText} machineCommand={machineCommand} machineActive={machineActive} disableScanButton={false} setScanButtonPressed={setScanButtonPressed}></ScanButton>
            <SlideButton machineScan={machineScan} scanButtonText={scanButtonText} machineCommand={machineCommand} machineActive={machineActive} disableScanButton={false} setScanButtonPressed={setScanButtonPressed}></SlideButton>
            <UploadButton machineScan={machineScan} machineActive={machineActive} disableUploadButton={disableUploadButton} uploadButtonText={uploadButtonText} ></UploadButton>
            <ExportButton machineScan={machineScan} machineActive={machineActive} disableExportButton={disableExportButton}></ExportButton>
        </Grid>
    } else if (scanAndUpload === false && uploadAndExport === true) {
        scanButtons = <Grid item container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            md={12}>
            <ScanButton machineScan={machineScan} scanButtonText={scanButtonText} machineCommand={machineCommand} machineActive={machineActive} disableScanButton={false} setScanButtonPressed={setScanButtonPressed}></ScanButton>
            <SlideButton machineScan={machineScan} scanButtonText={scanButtonText} machineCommand={machineCommand} machineActive={machineActive} disableScanButton={false} setScanButtonPressed={setScanButtonPressed}></SlideButton>
            <UploadAndExportButton machineScan={machineScan} machineActive={machineActive} disableUploadExportButton={disableUploadExportButton}></UploadAndExportButton>
        </Grid>
    } else if (scanAndUpload === true && uploadAndExport === true) {
        scanButtons = <Grid item container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            md={12}>
            <ScanButton machineScan={machineScan} scanButtonText={scanButtonText} machineCommand={machineCommand} machineActive={machineActive} disableScanButton={disableScanButton} setScanButtonPressed={setScanButtonPressed}></ScanButton>
            <SlideButton machineScan={machineScan} scanButtonText={scanButtonText} machineCommand={machineCommand} machineActive={machineActive} disableScanButton={disableScanButton} setScanButtonPressed={setScanButtonPressed}></SlideButton>
        </Grid>
    } else if (scanAndUpload === true && uploadAndExport === false) {
        scanButtons = <Grid item container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            md={12}>
            <ScanButton machineScan={machineScan} scanButtonText={scanButtonText} machineCommand={machineCommand} machineActive={machineActive} setScanButtonPressed={setScanButtonPressed}></ScanButton>
            <SlideButton machineScan={machineScan} scanButtonText={scanButtonText} machineCommand={machineCommand} machineActive={machineActive} setScanButtonPressed={setScanButtonPressed}></SlideButton>
            <ExportButton machineScan={machineScan} machineActive={machineActive} disableExportButton={disableExportButton}></ExportButton>
        </Grid>
    } else {
        scanButtons = <Grid item container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            md={12}>
            <Grid item>
                Unknown Error
            </Grid>
        </Grid>
    }

    if (!settingsLoaded) {
        return (
            <CircularProgress sx={{ color: "#F48430", mt: 2 }} />
        )
    } else {
        return (
            <Grid>{scanButtons}</Grid>
        )
    }
}