
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { Grid, Typography } from '@mui/material'

const styles = {
    customFieldsBox: {
        backgroundColor: '#FFFFFF',
        textArea: {
            color: '#4B4E65'
        },
        mt: 1
    }
}

export default function CustomFieldDropDown(props: any) {
    const { index, customField, updateCustomFieldSubmitValues, customFieldDropDownValues, customFieldSubmitValues } = props

    var selectedOptions = customFieldDropDownValues.map((item: any, i: any) => {
        if (item.customFieldID === customField.customFieldID) {
            return <MenuItem sx={styles.customFieldsBox.textArea} key={i} value={item.value ?? ""}>{item.value}</MenuItem>
        } return null;
    })

    const required = <Typography sx={{ color: 'red' }}>&nbsp;*&nbsp;</Typography>

    return (
        <Grid item md={11} xs={11} sx={styles.customFieldsBox}>
            <FormControl fullWidth>
                <TextField
                    select
                    value={customFieldSubmitValues['CustomFields']['Field'][index]['Value']}
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment sx={styles.customFieldsBox.textArea} position="start">{customField.name}  {customField.required ? required : ""}|</InputAdornment>
                        ),
                    }}
                    onChange={(element) => {
                        updateCustomFieldSubmitValues(index, element.target.value)
                    }}
                >
                    <MenuItem sx={styles.customFieldsBox.textArea} value={""}><em>None</em></MenuItem>
                    {selectedOptions}
                </TextField>
            </FormControl>
        </Grid>
    );
}