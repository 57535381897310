import { Modal, Grid, Box, Typography, Button, CircularProgress, Divider, TextField, MenuItem } from '@mui/material';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';

export default function AuditDims(props: any) {
    const { isOpen, setIsOpen, cargoResponse, machine } = props;
    const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(false);
    const [length, setLength] = useState<number>(0);
    const [width, setWidth] = useState<number>(0);
    const [height, setHeight] = useState<number>(0);
    const [badDimsSubmitted, setBadDimsSubmitted] = useState<boolean>(false);
    const [submissionAreaText, setSubmissionAreaText] = useState<string>("Dimensions submitted, you may no close this window")

    const toggleAuditBadDims = function () { setIsOpen(!isOpen) }

    const styles = {
        modal: {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: {
                xl: "30%",
                lg: "40%",
                md: "50%",
                sm: "95%",
                xs: "95%"
            },
            maxHeight: "95%",
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 3,
            overflow: 'auto',
            borderRadius: '16px'
        },
        badDimsText: {
            fontSize: '24px',
            fontWeight: 'bold',
            color: '#262D6D',
        },
        closeButton: {
            fontWeight: 700,
            float: 'right'
        },
        divider: {
            margin: '20px 0',
        },
        formControl: {
            marginTop: '20px',
        },
        button: {
            margin: '10px 0',
        },
    };

    useEffect(() => {
        if (isOpen) {
            setBadDimsSubmitted(false);
            setDisableSubmitButton(false);
            setSubmissionAreaText("Dimensions submitted for audting")
        }
    }, [isOpen])

    const createTimeString = function () {
        let timeString = cargoResponse.timeStamp.toLocaleTimeString()

        let dateString = cargoResponse.timeStamp.toLocaleDateString()

        // Parse the dateString
        let [month, day, year] = dateString.split('/').map(Number);

        // Parse the timeString
        let [time, period] = timeString.split(' ');
        let [hours, minutes, seconds] = time.split(':').map(Number);

        // Convert 12-hour time to 24-hour time
        if (period === 'PM' && hours !== 12) {
            hours += 12;
        } else if (period === 'AM' && hours === 12) {
            hours = 0;
        }

        // Format the date and time parts to ensure two digits where necessary
        let formattedDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
        let formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

        // Combine into the desired format
        let combinedDateTime = `${formattedDate}T${formattedTime}`

        return combinedDateTime;
    }

    async function submitBadDims(cmd: string) {
        console.log('request', commands[cmd]())
        setDisableSubmitButton(true);

        return await machine.connection.post(commands[cmd]())
            .then((response: any) => {
                if (_.has(response, 'Responses.ListDirectory.code') && response['Responses']['ListDirectory']['code'] === "0") {
                    if (response['Responses']['ListDirectory']['Directory'].length === 1) {
                        return true;
                    }
                } 
                setBadDimsSubmitted(true)
                setSubmissionAreaText('Error submitting, please close and try again');
                return false;
            })
            .then((endResult: boolean) => {
                if (endResult) {
                    machine.connection.post(commands['reportError']())
                        .then((response: any) => {
                            if (_.has(response, 'Responses.ReportError.code') && response['Responses']['ReportError']['code'] === "0") {
                                setBadDimsSubmitted(true)
                            } else {
                                setBadDimsSubmitted(true)
                                setSubmissionAreaText('Error submitting, please close and try again');
                            }
                        })
                }
            })
            .catch((err: any) => {
                console.log(err)
                setBadDimsSubmitted(true)
                setSubmissionAreaText('Error submitting, please close and try again');
            })
    }

    const commands: any = {
        checkCargoDirectory: () => {
            return {
                "Requests": {
                    "ListDirectory": {
                        "InlineInfo": false,
                        "Begin": createTimeString(),
                    }
                }
            }
        },
        reportError: () => {
            return {
                "Requests": {
                    "ReportError": {
                        "Length": Number(length),
                        "Width": Number(width),
                        "Height": Number(height)
                    }
                }
            }
        }
    }

    return (
        <Modal open={isOpen} onClose={toggleAuditBadDims}>
            <Grid container justifyContent="center" alignItems="center">
                <Box sx={styles.modal}>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item xs={6}>
                            <Typography sx={styles.badDimsText}>Report Bad Dims</Typography>
                        </Grid>
                        <Grid item md={6} xs={1}>
                            <Button className="Blue-Button" sx={styles.closeButton} style={{ float: 'right' }} onClick={toggleAuditBadDims}>Close</Button>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">

                        {cargoResponse ?
                            <Grid item xs={12} container direction="column">
                                <TextField
                                    label="Length"
                                    variant="outlined"
                                    type="number"
                                    value={length}
                                    onChange={(e: any) => { setLength(e.target.value) }}
                                    helperText={cargoResponse.units.length}
                                    sx={styles.formControl}
                                    InputProps={{ inputProps: { min: 0 } }}
                                />
                                <TextField
                                    label="Width"
                                    variant="outlined"
                                    type="number"
                                    value={width}
                                    onChange={(e: any) => { setWidth(e.target.value) }}
                                    helperText={cargoResponse.units.length}
                                    sx={styles.formControl}
                                    InputProps={{ inputProps: { min: 0 } }}
                                />
                                <TextField
                                    label="Height"
                                    variant="outlined"
                                    type="number"
                                    value={height}
                                    onChange={(e: any) => { setHeight(e.target.value) }}
                                    helperText={cargoResponse.units.length}
                                    sx={styles.formControl}
                                    InputProps={{ inputProps: { min: 0 } }}
                                />
                                {
                                    badDimsSubmitted ?
                                        <Typography display="flex" justifyContent="center" sx={styles.badDimsText}>{submissionAreaText}</Typography>
                                        :
                                        <>
                                            <Button variant="contained" color="primary" sx={styles.button} disabled={disableSubmitButton} onClick={() => {
                                                submitBadDims('checkCargoDirectory')
                                            }}>Submit</Button>
                                            <Button variant="outlined" color="primary" sx={styles.button} onClick={toggleAuditBadDims}>Cancel</Button>
                                        </>
                                }
                            </Grid>
                            :
                            <Grid item> No Cargo to Report Dims On </Grid>
                        }
                        {/* <Divider orientation="vertical" flexItem />
                        <Grid item xs={12} container direction="column" alignItems="center" justifyContent="center">
                                <Button variant="contained" color="primary" sx={styles.button}>
                                    Calibrate
                                </Button>
                            <Button variant="contained" color="primary" sx={styles.button}>
                                Audit Successful Calibrations
                            </Button>
                            <Button variant="contained" color="secondary" sx={styles.button}>
                                Audit Failed Calibrations
                            </Button>
                        </Grid> */}
                    </Grid>
                </Box>
            </Grid>
        </Modal>
    )
}