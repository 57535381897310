import _ from 'lodash'
import { hasCertifiableDimensions } from './Common'

type dimensionObject = {
    length: number
    width: number
    height: number
    density: number
    volume: number
    weight: weightObject
    dimensionBounds: dimensionCertificationsObject
}

type weightObject = {
    net: number
    gross: number
    display: number
    tare: number
}

type unitsObject = {
    length: string
    volume: string
    density: string
    weight: string
}

type filesListObject = {
    clouds: string[];
    images: string[];
    xmls: string[];
    misc: string[];
}

type dimensionCertificationsObject = {
    length: boolean;
    width: boolean;
    height: boolean;
    overall: boolean;
}

type customFieldsObject = {
    CustomFields: {
        Field: any[]
    }
}


export class Cargo {
    cargoID: string = "";
    dimensionCode: string = "";
    dimensionCodeDescription: string = "";
    uploadCode: string = "";
    uploadCodeDescription: string = "";
    exportCode: string = "";
    exportCodeDescription: string = "";
    certification: any = {};
    dimensions: dimensionObject = {
        length: 0,
        width: 0,
        height: 0,
        density: 0,
        volume: 0,
        weight: {
            net: 0,
            gross: 0,
            display: 0,
            tare: 0
        },
        dimensionBounds: {
            length: true,
            width: true,
            height: true,
            overall: true
        }
    };
    customFields: customFieldsObject = {
        CustomFields: {
            Field: []
        }
    }

    units: unitsObject = {
        length: "",
        volume: "",
        density: "",
        weight: ""
    }

    fileList: filesListObject = {
        clouds: [],
        images: [],
        xmls: [],
        misc: []
    }
    barcode: string = "";
    timeStamp: Date = new Date(Date.now());
    uploaded: boolean | null = null;
    exported: boolean | null = null;

    parseResponse(this: Cargo, unknownResponse: any) {
        if (!("Responses" in unknownResponse)) {
            console.log('Error: unknown response')
            this.dimensionCode = "error"
            this.uploadCode = "error"
            this.exportCode = "error"
        }
        if ("Dimension" in unknownResponse.Responses) {
            this.parseDimensionResponse(unknownResponse)
        }
        if ("Snapshot" in unknownResponse.Responses) {
            this.parseSnapshotResponse(unknownResponse)
        }
        if ("Upload" in unknownResponse.Responses) {
            this.parseUploadResponse(unknownResponse)
        }
        if ("Export" in unknownResponse.Responses) {
            this.parseExportResponse(unknownResponse)
        }
    }

    parseDimensionResponse(this: Cargo, dimensionResponse: any) {
        if (this.checkMachineResponseSuccessCode(dimensionResponse, 'Dimension')) {
            this.dimensionCode = "0"
            this.setCargoDetails(dimensionResponse)
        } else {
            this.dimensionCode = "error"
            this.dimensionCodeDescription = dimensionResponse['Responses']['Dimension']['description']
        }
    }

    parseSnapshotResponse(this: Cargo, snapshotResponse: any) {
        if (this.checkMachineResponseSuccessCode(snapshotResponse, 'Snapshot')) {
            this.setCargoSnapshots(snapshotResponse)
        } else {
            this.dimensionCode = "error"
            this.dimensionCodeDescription = snapshotResponse['Responses']['Snapshot']['description']
        }
    }

    parseUploadResponse(this: Cargo, uploadResponse: any) {
        if (this.checkMachineResponseSuccessCode(uploadResponse, 'Upload')) {
            this.uploaded = true
            this.uploadCode = "0"
            this.cargoID = uploadResponse['Responses']['Upload']['Server']['cargoID']
        } else {
            this.uploaded = false
            this.uploadCodeDescription = uploadResponse['Responses']['Upload']['description']
        }
    }

    parseExportResponse(this: Cargo, exportResponse: any) {
        if (this.checkMachineResponseSuccessCodeExport(exportResponse, 'Export')) {
            this.exported = true
            this.exportCode = "0"
        } else {
            this.exported = false
            this.exportCode = exportResponse['Responses']['Export']['EndpointResponse']['StatusCode']
            this.exportCodeDescription = exportResponse['Responses']['Export']['EndpointResponse']['Reason']
        }
    }

    checkMachineResponseSuccessCode(response: any, eventType: string) {
        return _.get(response, `Responses.${eventType}.code`) === "0"
    }

    checkMachineResponseSuccessCodeExport(response: any, eventType: string) {
        let statusCodeNum = parseInt(_.get(response, `Responses.${eventType}.EndpointResponse.StatusCode`))
        if ((_.get(response, `Responses.${eventType}.code`) === "0") && (statusCodeNum >= 200 && statusCodeNum < 400)) {
            return true
        } return false
    }

    setCargoDetails(this: Cargo, dimensionResponse: any) {
        let responseDims = dimensionResponse['Responses']['Dimension']['Info']['Dimensions']
        let responseUnits = dimensionResponse['Responses']['Dimension']['Info']['Units']
        this.barcode = responseDims['Barcode']
        this.dimensions.length = responseDims['Length']
        this.dimensions.width = responseDims['Width']
        this.dimensions.height = responseDims['Height']
        this.dimensions.density = responseDims['Density']
        this.dimensions.volume = responseDims['Volume']
        this.dimensions.weight.gross = responseDims['Weight']['Gross']
        this.dimensions.weight.net = responseDims['Weight']['Net']
        if (responseDims['Weight']['Gross'] > responseDims['Weight']['Net']){
            this.dimensions.weight.display = this.dimensions.weight.gross
        }
        else{
            this.dimensions.weight.display = this.dimensions.weight.net
        }
        this.dimensions.weight.tare = responseDims['Weight']['Tare']
        this.units.density = responseUnits['Density']
        this.units.length = responseUnits['Length']
        this.units.weight = responseUnits['Weight']
        this.units.volume = responseUnits['Volume']
        if (dimensionResponse['Responses']['Dimension']['Info']['Scanner']['Certified'] === 'null') {
            this.dimensions.dimensionBounds.overall = false;
            this.dimensions.dimensionBounds.length = hasCertifiableDimensions(this.dimensions.length, this.units.length, this.certification['LengthBounds'][0], this.certification['LengthBounds'][1]);
            this.dimensions.dimensionBounds.width = hasCertifiableDimensions(this.dimensions.width, this.units.length, this.certification['WidthBounds'][0], this.certification['WidthBounds'][1]);
            this.dimensions.dimensionBounds.height = hasCertifiableDimensions(this.dimensions.height, this.units.length, this.certification['HeightBounds'][0], this.certification['HeightBounds'][1]);
        }
    }

    setCargoSnapshots(this: Cargo, dimensionResponse: any) {
        let snapshotDirectory = dimensionResponse['Responses']['Snapshot']['Directory']

        _.has(dimensionResponse['Responses']['Snapshot']['Directory'], 'Clouds') ? this.fileList.clouds = snapshotDirectory['Clouds']['Path'] : this.fileList.clouds = []
        _.has(dimensionResponse['Responses']['Snapshot']['Directory'], 'Images') ? this.fileList.images = snapshotDirectory['Images']['Path'] : this.fileList.images = []
        _.has(dimensionResponse['Responses']['Snapshot']['Directory'], 'Misc') ? this.fileList.misc = snapshotDirectory['Misc']['Path'] : this.fileList.misc = []
        _.has(dimensionResponse['Responses']['Snapshot']['Directory'], 'Xmls') ? this.fileList.xmls = snapshotDirectory['Xmls']['Path'] : this.fileList.xmls = []

        this.fileList.images = this.fileList.images.sort((a, b) => {
            if (a.includes("marked") && !b.includes("marked")) {
                return -1;
            } else if (!a.includes("marked") && b.includes("marked")) {
                return 1;
            } else {
                return 0;
            }
        });
        // xml time stamp logic
        let date = new Date(Date.now());
        try {
            let filePath = this.fileList.images[0];
            let timeStamp = filePath.split('/')[0];
            let splitTwo = timeStamp.split('_')
            let dateArray = [Number(splitTwo[2])]; //year
            dateArray[1] = (Number(new Date(splitTwo[0].split('-')[0] + " 1, 2012").toLocaleDateString('en', { month: '2-digit' })) - 1)//month
            dateArray[2] = Number(splitTwo[0].split('-')[1]) //day
            let time = splitTwo[1].split("-");
            date = new Date(dateArray[0],dateArray[1],dateArray[2],Number(time[0]),Number(time[1]),Number(time[2]));
        } catch {
            console.log("Error setting cargo time stamp")
        }
        if(isNaN(date.getTime())) {
            date = new Date(Date.now());
        }
        this.timeStamp = date;
    }

    constructor(_certification: any, _customfields: any) {
        this.certification = _certification
        this.customFields = _customfields
    }
}