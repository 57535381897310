import { Machine } from './Connections';

const API_ENDPOINT = process.env.REACT_APP_MY_HOST || "https://spectre-licensing.com"

export function logIntoHost(credentials: string, spectreLoginUrl: string): Promise<boolean> {
    return fetch(spectreLoginUrl, {
        method: 'POST',
        body: `Credentials=${credentials}`,
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    }).then((response: any) => {
        return response;
    })
}

export function removeUser64Cookie() {
    document.cookie = "user64=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export function getCustomFields(): Promise<any> {
    return fetch(`${API_ENDPOINT}/api/CustomFields`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    })
        .then(data => data.json())
        .then((data) => {
            return data;
        })
}

export async function handleTextChange(elementValue: any, settingPath: string, machine: Machine) {
    elementValue = elementValue.replace(/ /g, '%5C%20');
    elementValue = elementValue.replace("+", '%5C+');

    await machine.connection.get(`SetSetting?Path=/Settings/User/${settingPath}&Value=${elementValue}`)
        .catch((err: any) => {
            alert('Machine Settings Not Saved, Please Check Machine is On')
        })
}

export async function retrieveCertification(machine: Machine) {
    let command: any = {
        "Requests": {
            "GetCertification": {
            }
        }
    }
    return await machine.connection.post(command)
        .then((response: any) => {
            return response;
        })
}

export function getCookie(cname: any) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function hasCertifiableDimensions(scannedDimension: any, unitMeasurement: string, minDimension: number, maxDimension: number) {
    const units: any = {
        'millimeters': 0,
        'centimeters': 1,
        'meters': 2,
        'inches': 3,
        'feet': 4,
        'millimeter': 0,
        'centimeter': 1,
        'meter': 2,
        'inch': 3,
        'foot': 4,
        'mm': 0,
        'cm': 1,
        'm': 2,
        'in': 3,
        'ft': 4,
    }

    if (unitMeasurement.toLowerCase() !== "inches" || unitMeasurement.toLowerCase() !== "inch" || unitMeasurement.toLowerCase() !== "in") {
        scannedDimension = convertUnits(scannedDimension, units[unitMeasurement], 3)
    }

    if (scannedDimension >= minDimension && scannedDimension <= maxDimension) {
        return true;
    } else {
        return false;
    }
}

export function convertUnits(value: number, currentUnit: number, desiredUnit: number): number {
    // Conversion matrix for millimeters, centimeters, meters, inches, and feet
    const conversionMatrix: number[][] = [
        //       mm    cm    m      in      ft
        /* mm */[1, 0.1, 0.001, 0.0394, 0.00328084],
        /* cm */[10, 1, 0.01, 0.3937, 0.0328084],
        /* m  */[1000, 100, 1, 39.3701, 3.28084],
        /* in */[25.4, 2.54, 0.0254, 1, 0.0833],
        /* ft */[304.8, 30.48, 0.3048, 12, 1]
    ];

    // Perform the conversion using the conversion matrix
    const conversionFactor: number = conversionMatrix[currentUnit][desiredUnit];
    const convertedValue: number = value * conversionFactor;

    return convertedValue;
}

export function splitAndFormatString(input: string) {
    // Use a regular expression to find the points where a capital letter follows a lowercase letter
    let result = input.replace(/([a-z])([A-Z])/g, '$1 $2');

    // Convert the entire string to lowercase
    result = result.toLowerCase();

    // Capitalize the first word
    result = result.charAt(0).toUpperCase() + result.slice(1);

    return result;
}

// Helper function for comparing version strings (adapted from: https://gist.github.com/TheDistantSea/8021359)
export function versionCompare (v1: any, v2: any, options: any) {
    let lexicographical = (options && options.lexicographical);
    let zeroExtend = (options && options.zeroExtend);
    let v1parts = v1.split('.');
    let v2parts = v2.split('.');

    function isValidPart(x: any) {
        return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
    }

    if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
        return NaN;
    }

    if (zeroExtend) {
        while (v1parts.length < v2parts.length) v1parts.push("0");
        while (v2parts.length < v1parts.length) v2parts.push("0");
    }

    if (!lexicographical) {
        v1parts = v1parts.map(Number);
        v2parts = v2parts.map(Number);
    }

    for (let i = 0; i < v1parts.length; ++i) {
        if (v2parts.length === i) {
            return 1;
        }

        if (v1parts[i] === v2parts[i]) {
            continue;
        }
        else if (v1parts[i] > v2parts[i]) {
            return 1;
        }
        else {
            return -1;
        }
    }

    if (v1parts.length !== v2parts.length) {
        return -1;
    }

    return 0;
};