import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Grid, Typography } from '@mui/material';

const styles = {
    customFieldsBox: {
        backgroundColor: '#FFFFFF',
        textArea: {
            color: '#4B4E65'
        },
        mt: 1
    }
}

export default function CustomFieldNumberBox(props: any) {
    const { index, customField, updateCustomFieldSubmitValues, customFieldSubmitValues } = props

    const required = <Typography sx={{ color: 'red' }}>&nbsp;*&nbsp;</Typography>

    return (
        <Grid item md={11} xs={11} sx={styles.customFieldsBox}>
            <TextField color="primary" type="number" variant="outlined" size="small" value={customFieldSubmitValues['CustomFields']['Field'][index]['Value']} fullWidth 
                onChange={(element) => { 
                    updateCustomFieldSubmitValues(index, element.target.value) 
                }}
                    InputProps={{
                        startAdornment: <InputAdornment sx={styles.customFieldsBox.textArea} position="start">{customField.name} {customField.required ? required : ""} | </InputAdornment>,
                    }}
                />
        </Grid>
    );
}