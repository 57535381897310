
import { Box } from '@mui/material';
import 'react-alice-carousel/lib/alice-carousel.css';

export default function ScanImage(props: any) {
    const { item, height, width } = props;

    const handleDragStart = (e: any) => e.preventDefault();

    return (
        <Box
            component="img"
            sx={{
                width: width,
                height: height
            }}
            style={{
                margin: 'auto',

            }}
            src={item}
            onDragStart={handleDragStart}
            role="presentation"
            alt="https://cargospectre.blob.core.windows.net/assets/images/cargo-placeholder.png"
        >
        </Box>
    )
}