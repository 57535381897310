import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Grid, Box, Tab, Tabs, Modal, Button, Typography } from '@mui/material'
import "./MachineSettings.css";
import TextSetting from '../machineSettingsComponents/TextSetting';
import ToggleSetting from '../machineSettingsComponents/ToggleSetting';
import DropDownSetting from '../machineSettingsComponents/DropDownSetting';
import { splitAndFormatString } from '../../../../ts/Common';
import _ from 'lodash'

const MY_HOST = process.env.REACT_APP_MY_HOST || "https://spectre-licensing.com"

export default function MachineSettings(props: any) {
    const { isOpen, setIsOpen, settings, setSettings, setSettingsLoaded, machine } = props
    const [settingsArray, setSettingsArray] = useState(new Array<any>())
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    const navigate = useNavigate();

    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
    }

    function CustomTabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    function navigateToMatchmaking() {
        alert("Failed to connect to machine, redirecting to matchmaking");
        navigate("/matchmaking");
    }

    const handleTabChange = (e: any, tabIndex: any) => {
        setCurrentTabIndex(tabIndex);
    };

    const toggleMachineSettings = function () {
        return setIsOpen(!isOpen)
    }

    const add_value_recursion = (src: any, dst: any) => {
        for (var name in src) {
            var node = src[name]
            if (typeof node === 'object') {
                add_value_recursion(node, dst[name])
            } else if (dst) {
                let this_node = dst[name]
                this_node.value = node

                this_node.Set = async (elementValue: any) => {
                    const settingPath = this_node['path'].join('/')

                    const postBody: any = {
                        "Requests": {
                            "SetSetting": {
                                "Path": `/Settings/${settingPath}`,
                                "Value": elementValue
                            }
                        }
                    }

                    await machine.connection.post(postBody)
                        .catch((err: any) => {
                            console.log("Error setting machine value", err)
                            throw Error(err.statusText);
                        })
                    this_node.value = elementValue
                }
            }
        }
    }

    const path_resolve = (obj: any, searchKey: string = "value", path: Array<any> = [], results: Array<any> = []) => {
        const r = results;
        Object.keys(obj).forEach(key => {
            const next_obj = obj[key];
            if (key === searchKey) {
                obj.path = [...path]
                obj.readonly = obj.Permission === 'r'
                r.push(obj);
            } else if (typeof next_obj === 'object') {
                path.push(key)
                path_resolve(next_obj, searchKey, path, r);
                path.pop();
            }
        });
        return r;
    };

    useEffect(() => {
        machine ? machine.connection.ping()
            .then((response: boolean) => {
                if (!response) {
                    console.log('machine ping failure, redirecting to matchmaking')
                    navigate('/matchmaking')
                } else {
                    machine.connection.get('GetExportFormats')
                        .then((data: any) => {
                            if (data) {
                                if (_.has(data['Responses']['GetExportFormats'], 'Formats')) {
                                    let formats = data['Responses']['GetExportFormats']['Formats']
                                    machine.settings['Export']['Format']['Option'] = formats;
                                }
                            }
                        })
                        .then(() => {
                            machine.connection.get("GetSettings%20GetSettingsInfo")
                                .then((data: any) => {
                                    let settings_values: any = data["Responses"]["GetSettings"]["Settings"]

                                    let settings_user = machine.settings;

                                    settings_user.sync_from_json(settings_values["User"])
                                    delete settings_values["User"]

                                    let settings_info: any = data["Responses"]["GetSettingsInfo"]["Settings"]

                                    let settings_total: any = settings_info
                                    add_value_recursion(settings_values, settings_total)

                                    for (let i = 0; i < settings_user.All.length; i++) {
                                        let path_array = settings_user.All[i]['Path'].split("/");
                                        let node_one = path_array[0];
                                        let node_two = path_array[1];

                                        if (settings_total[node_one] === undefined) {
                                            settings_total[node_one] = {}
                                        }
                                        settings_total[node_one][node_two] = settings_user.All[i]
                                    }

                                    setSettings(settings_total)

                                    let paths_array: Array<any> = path_resolve(settings_total);

                                    setSettingsArray(paths_array);
                                    setSettingsLoaded(true);
                                })
                        })
                }
            })
            : navigate('/matchmaking')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function updateMachineLocation(e: any) {
        const result = await fetch(`${MY_HOST}/api/Matchmaking/EditLocation`, {
            method: 'POST',
            credentials: 'include',
            body: `location=${e}&id=${machine.matchmakingID}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
            .then(data => {
                if (data.ok) {
                    return data.json();
                } else {
                    alert("failed to update machine location");
                }
            })
            .then(data => {
                if (data.error == null) {
                    console.log('machine location updated');
                } else {
                    console.log(data.error)
                    alert("failed to update machine location");
                }
            })
        return result;
    }

    const styles: any = {
        modal: {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: {
                md: "55%",
                xs: "90%"
            },
            height: "75%",
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            overflow: 'auto',
            borderRadius: '16px'
        },
        closeButton: {
            fontWeight: 700,
        },
        tabList: {
            allowScrollButtonsMobile: true
        },
        machineSettingsText: {
            fontSize: 28,
            fontWeight: 500,
            color: '#262D6D',
        }
    }

    return (
        <Modal
            open={isOpen}
            onClose={toggleMachineSettings}
        >
            <Grid container>
                <Box sx={styles.modal}>
                    <Grid container
                        direction="row"
                        justifyContent="space-between"
                    >
                        <Grid item md={6} xs={11}>
                            <Typography sx={styles.machineSettingsText}>Settings</Typography>
                        </Grid>
                        <Grid item md={6} xs={1}>
                            <Button className="Blue-Button" sx={styles.closeButton} style={{ float: 'right' }} onClick={toggleMachineSettings}>Close</Button>
                        </Grid>
                    </Grid>
                    <Tabs value={currentTabIndex} variant="scrollable" allowScrollButtonsMobile onChange={handleTabChange}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: "#D97D54",
                            }
                        }}>
                        {
                            Object.keys(settings).map((e, i) => {
                                return <Tab key={i} sx={{
                                    color: "#262D6D",
                                    "&.Mui-selected": {
                                        color: "#F48430"
                                    }
                                }} label={`${e}`}></Tab>
                            })
                        }
                    </Tabs>
                    {
                        Object.keys(settings).map((e, i) => {

                            let section: any = [];
                            return <CustomTabPanel key={i} value={currentTabIndex} index={i}>

                                {
                                    settingsArray.map((element, index) => {
                                        let path = element['path']

                                        if (path[0] !== e) return '';

                                        let display_name = element['DisplayName']
                                        if (display_name === undefined || display_name === "no name") display_name = path.at(-1)

                                        display_name = splitAndFormatString(display_name)

                                        // section header / linebreak logic
                                        let insert_line = false;

                                        if (path.length > 2 && section[1] !== path[1]) {
                                            insert_line = true;
                                            section = path;
                                        }

                                        let lineBreaker = <Typography key={index} sx={{ fontWeight: 500, fontSize: 16, color: '#636383', pt: 1, pl: 2 }}>{splitAndFormatString(String(path[1]))}</Typography>

                                        let elementType = element['Ui']

                                        switch (true) {
                                            case elementType === 'toggle':
                                                switch (true) {
                                                    case element.readonly:
                                                        return <div key={index}>
                                                            {insert_line ? lineBreaker : ''}
                                                            <ToggleSetting id={display_name} index={index} display_name={display_name} element={element} disabledState={true} setSettingsArray={setSettingsArray}></ToggleSetting>
                                                        </div>
                                                    default:
                                                        return <div key={index}>
                                                            {insert_line ? lineBreaker : ''}
                                                            <ToggleSetting id={display_name} index={index} display_name={display_name} element={element} disabledState={false} setSettingsArray={setSettingsArray} navigateToMatchmaking={navigateToMatchmaking}></ToggleSetting>
                                                        </div>
                                                }
                                            case elementType === 'text':
                                                switch (true) {
                                                    case element.readonly:
                                                        return <div key={index}>
                                                            {insert_line ? lineBreaker : ''}
                                                            <TextSetting id={display_name} inputType={String("text")} element={element} display_name={display_name} updateMachineLocation={updateMachineLocation} disabledState={true}></TextSetting>
                                                        </div>
                                                    default:
                                                        return <div key={index}>
                                                            {insert_line ? lineBreaker : ''}
                                                            <TextSetting id={display_name} inputType={String("text")} element={element} display_name={display_name} updateMachineLocation={updateMachineLocation} disabledState={false} navigateToMatchmaking={navigateToMatchmaking}></TextSetting>
                                                        </div>
                                                }
                                            case elementType === 'password':
                                                switch (true) {
                                                    default:
                                                        return <div key={index}>
                                                            {insert_line ? lineBreaker : ''}
                                                            <TextSetting id={display_name} inputType={String("password")} element={element} display_name={display_name} updateMachineLocation={updateMachineLocation} passwordState={true} navigateToMatchmaking={navigateToMatchmaking}></TextSetting>
                                                        </div>
                                                }
                                            case elementType === 'ticker':
                                                switch (true) {
                                                    case element.readonly:
                                                        return <div key={index}>
                                                            {insert_line ? lineBreaker : ''}
                                                            <TextSetting id={display_name} inputType={String("number")} element={element} display_name={display_name} updateMachineLocation={updateMachineLocation} disabledState={true}></TextSetting>
                                                        </div>
                                                    default:
                                                        return <div key={index}>
                                                            {insert_line ? lineBreaker : ''}
                                                            <TextSetting id={display_name} inputType={String("number")} element={element} display_name={display_name} updateMachineLocation={updateMachineLocation} disabledState={false} navigateToMatchmaking={navigateToMatchmaking}></TextSetting>
                                                        </div>
                                                }
                                            case elementType === 'select':
                                                return <div key={index}>
                                                    {insert_line ? lineBreaker : ''}
                                                    <DropDownSetting id={display_name} element={element} display_name={display_name} machine={machine} navigateToMatchmaking={navigateToMatchmaking}></DropDownSetting>
                                                </div>
                                            default:
                                                return <div key={index}>
                                                    {insert_line ? lineBreaker : ''}
                                                    {display_name} : {(element['value'])}
                                                </div>
                                        }
                                    })
                                }
                            </CustomTabPanel>
                        })
                    }
                </Box>
            </Grid>
        </Modal>
    )
}