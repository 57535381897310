import React, { useState } from 'react';
import Draggable, { DraggableEvent, DraggableData } from 'react-draggable';
import { Button, Grid, Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export default function SlideButton(props: any) {
    const { machineScan, scanButtonText, machineCommand, machineActive, disableScanButton, setScanButtonPressed } = props
    const [buttonPosition] = useState({ x: 0, y: 0 });
    const [opacity, setOpacity] = useState(0.2);
    const [margin, setMargin] = useState(0);

    const handleDrag = (e: DraggableEvent, data: DraggableData) => {
        // Sets opacity relative to the x axis we're dragging on, may need to be changed depending on screen sizes? 
        const opacity = Math.min(1, data.x / 400);
        setOpacity(opacity);

        const margin = Math.min(data.x / 100);
        setMargin(margin);
    };

    // Function that handles when drag is released 
    const handleStop = (e: DraggableEvent, data: DraggableData) => {
        if (data.x >= 200) {
            machineScan(`${machineCommand}`)
            console.log('Trigger function executed!');
        }
        // Sets the Margin back to 0 
        setMargin(0)
    };


    const styles = {
        display: {
            display: {
                sm: 'block',
                md: 'none'
            }
        },
        container: {
            position: 'relative',
            height: '65px',
            color: '#FFFFFF',
            backgroundColor: `rgba(0, 0, 75, ${opacity})`,
            overflow: 'hidden',
            borderRadius: '5px',
            fontWeight: 700,
            fontSize: 20,
            ml: margin,
        },
        containerTypography: {
            fontWeight: 700,
            fontSize: 20,
            ml: 2
        },
        scanButton: {
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            fontWeight: 700,
            fontSize: 20,
        },
        CircularProgress: {
            mr: 1,
            color: '#FFFFFF',
        },
    };

    if (!machineActive) {
        return (
            <Grid sx={styles.display} item md={11} xs={11} pt={2}>
                <Box sx={styles.container} display="flex" alignItems="center">
                    <Typography sx={styles.containerTypography}>  &#9654; &#9654; &#9654; </Typography>
                    <Draggable
                        axis="x"
                        handle=".handle"
                        bounds={{ left: 0, right: 400 }} // Adjust the bounds accordingly
                        position={buttonPosition}
                        onDrag={handleDrag}
                        onStop={handleStop}
                    >
                        <Button className="handle Scan-Button" sx={styles.scanButton} disabled={disableScanButton} fullWidth>
                            {scanButtonText} &#9654; &#9654; &#9654;
                        </Button>
                    </Draggable>
                </Box>
            </Grid>
        );
    } else {
        return (
            <Grid sx={styles.display} item md={11} xs={11} pt={2}>
                <Button className="Scan-Button" disabled fullWidth onClick={() => { setScanButtonPressed(true) }}><CircularProgress sx={styles.CircularProgress} size={25} />Scanning...</Button>
            </Grid>
        )
    }
}