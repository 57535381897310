import { List } from '@mui/material'
import PictureScanEvent from './scanHistoryComponents/PictureScanEvent';
import React from 'react'
import ScanFailure from './scanHistoryComponents/ScanFailure';


export default function ScanHistory(props: any) {
    const { selectedScanIndex, setSelectedScanIndex, cargoResponses, setOpenScanDetails, openDetailsModal, machine } = props

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedScanIndex(index);
    };

    return (
        <List>
            {cargoResponses.map((response: any, index: any) => {
                return (
                    cargoResponses[index].dimensionCode !== "0" ?
                        <React.Fragment key={index}>
                            <ScanFailure key={index} cargoResponse={cargoResponses[index]} handleListItemClick={handleListItemClick}
                                cargoResponseIndex={index} selectedScanIndex={selectedScanIndex} openDetailsModal={openDetailsModal}></ScanFailure>

                        </React.Fragment>
                        :
                        <React.Fragment key={index}>
                            <PictureScanEvent
                                key={index} cargoResponse={cargoResponses[index]} scanFailure={false}
                                cargoResponseIndex={index} handleListItemClick={handleListItemClick} selectedScanIndex={selectedScanIndex}
                                setOpenScanDetails={setOpenScanDetails} openDetailsModal={openDetailsModal} machine={machine} index={index}></PictureScanEvent>
                        </React.Fragment>
                )
            })}
        </List>
    )
}