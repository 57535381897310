import { Grid, Checkbox } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel';

const styles = {
    customFieldsBox: {
        backgroundColor: '#FFFFFF',
        textArea: {
            color: '#636383'
        },
        mt: 1
    }
}

export default function CustomFieldNumberBox(props: any) {
    const { index, customField, updateCustomFieldSubmitValues, customFieldSubmitValues } = props

    return (
        <Grid item md={11} xs={11} >
            <FormControlLabel
                style={{ float: "left", color: '#4B4E65' }}
                control={<Checkbox sx={styles.customFieldsBox.textArea} checked={customFieldSubmitValues['CustomFields']['Field'][index]['Value']} />}
                label={customField.name}
                onChange={(element: any) => {
                    updateCustomFieldSubmitValues(index, element.target.checked)
                }} />
        </Grid>
    );
}