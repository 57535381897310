import { IConnection } from "./Connections";

export class Setting {
    DefaultValue: string | boolean = ""
    Ui: string = "text"
    Category: string = "regex"
    Type: string = "string"
    Option: string | string[] | boolean | boolean[] = false
    Permission: string = "rw"    

    readonly: boolean = false
    value: string | boolean = ""
    Name: string = "name"
    Path: string = ""
    Connection: IConnection | null = null
    
    async Get(): Promise<string> {
        return this.Connection!.get(`GetSetting?Path=${this.Path}`)
            .then((data: any) => {
                let response = data["Responses"]["GetSetting"]
                if (response["code"] !== "0") return this.DefaultValue;
                return response["value"]
            })
            .then((value: string) => this.value = value)
    }

    async Set(value: string): Promise<string> {
        const postBody: any = {
            "Requests": {
                "SetSetting": {
                    "Path": `/Settings/User/${this.Path}`,
                    "Value": value,
                    "Type": this.Type
                }
            }
        }

        return this.Connection!.post(postBody)
            .then((data: any) => {
                let response = data["Responses"]["SetSetting"]
                if (response["code"] !== "0") throw Error()
                return response["value"]
            })
            .then((value: string) => this.value = value)
            .catch((err: any) => {
                console.log("Error setting user value", err)
                throw Error(err.statusText);
            })
    }
}

// create new classes 
class Integer extends Setting {
    constructor() {
        super()
        this.Category = "regex";
        this.Option="[1-9][0-9]+"
        this.Ui = "ticker"
        this.DefaultValue = "0"
        this.Type = "integer"
    }
}

class Text extends Setting {
    constructor(default_value: string = "") {
        super()
        this.Option = ".*"
        this.Category = this.Ui = "text"
        this.DefaultValue = default_value
    }
}

class Password extends Text {
    constructor() {
        super()
        this.Category = this.Ui = "password"
    }
}

class Select extends Setting {
    constructor(options: string[], type: string = "select") {
        super()
        this.Option = options
        this.Category = this.Ui = type
        this.DefaultValue = options[0]
        this.value = this.DefaultValue
    }
}

class Toggle extends Setting {
    constructor() {
        super();
        this.Category = this.Ui = "toggle";
        this.Type = "boolean";
        this.Option = [false, true];
        this.DefaultValue = false;
        this.value = this.DefaultValue;
    }
}

export class Settings {
    Connection: IConnection | null = null
    All: Array<Setting>

    [key: string]: any
    ensure(obj: any, name: string) : any {
        if (obj[name] === undefined) {
            obj[name] = {}
        }
        return obj[name]
    }
    create(path: Array<string>, base: Setting): Setting {
        let opt: Setting = base
        opt.Connection = this.Connection
        opt.Name = path[path.length-1]
        opt.Path = path.join('/')

        let node: any = this
        for (var i=0; i<path.length-1; ++i) {
            node = this.ensure(node, path[i])
        }
        node[opt.Name] = opt;
        this.All.push(opt)
        return opt
    }
    constructor(connection: IConnection | null = null) {
        this.Connection = connection
        this.All = new Array<Setting>()

        // JUST DO THIS FOR NOW
        // Second parameter should be the second parameter of this.create and it will create the rest 
        // should be handled in ensure? 
        this.create(['Scanner', 'ClearCustomFields'], new Toggle())
        this.create(['Export', 'Method'], new Select(["HTTPS", "FTP", "SFTP"]))
        this.create(['Export', 'Url'], new Text())
        this.create(['Export', 'AuthHeader'], new Password())
        // these are for a drop down menu - there's JSON, CSV, CrushFTP, etc. - can be hardcoded in html
        this.create(['Export', 'Format'], new Select(['JSON', 'XML', 'CSV', 'CrushFTP', 'Synapse', 'AGICS', 'Acumatica', 'CargoSpot', 'KCR', 'TruckMate', 'BatchJSON', 'GTLPTM', 'OngoingWMS', 'ThreePLCentral', 'TruckMateTracenumber', 'WF']))
        this.create(['Export', 'Username'], new Text())
        this.create(['Export', 'Password'], new Password())
        this.create(['User', 'Location'], new Text())
    }

    sync_from_json(json: any) {
        if (json === undefined) return
        this.All.forEach((setting: Setting) => {
            let path: Array<string> = setting.Path.split('/')

            let json_node = json;
            for (let i = 0; i < path.length; ++i) {
                let path_node: string = path[i]

                json_node = json_node[path_node]
                if (json_node === undefined) return
            }
            setting.value = json_node
        })
    }
    sync() : Promise<any>{
        return this.Connection!.get("GetSettings?Path=/Settings/User")
        .then((json: any) => json["Responses"]["GetSettings"]["User"])
        .then((json: any) => this.sync_from_json(json))
    }
    json() {
        let root: any = {
            Settings: {
                User: {}
            }
        }
        let user_root: any = root["Settings"]["User"]

        let example_setting: Setting = this.All[0];
        let result: any = user_root
        let path: string[] = example_setting.Path.split('/').splice(3)
        for (let i=0; i<path.length; ++i) {
            result = this.ensure(result, path[i])
        }
        result['Permission'] = 'rw'
        result['Ui'] = example_setting.Ui

        return root
    }
}