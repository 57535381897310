
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material'
import { retrieveCertification } from '../../../../ts/Common';

const styles = {
    dropDownSettingsBox: {
        backgroundColor: '#FFFFFF',
        textArea: {
            color: '#4B4E65'
        },
        mt: 1
    }
}

export default function DropDownSetting(props: any) {
    const { element, display_name, machine, navigateToMatchmaking } = props;

    var selectOptions = element['Option'].map((item: any, i: any) => {
        return <MenuItem key={i} value={item}>{item}</MenuItem>
    })

    return (
        <Grid item xs={12} lg={6} sx={styles.dropDownSettingsBox}>
            <FormControl fullWidth>
                <TextField
                    select
                    defaultValue={element['value']}
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment sx={styles.dropDownSettingsBox.textArea} position="start">{display_name} :</InputAdornment>
                        )
                    }}
                    onChange={(e: any) => {
                        if (display_name === "Certification") {
                            element.Set(e.target.value)
                                .then((data: any) => {
                                    retrieveCertification(machine)
                                        .then((data: any) => {
                                            if (data['Responses']['GetCertification']['code'] === '0') {
                                                machine.certification = data['Responses']['GetCertification']
                                            }
                                        })
                                        .catch(() => {
                                            navigateToMatchmaking();
                                        })
                                })
                        } else {
                            element.Set(e.target.value)
                                .catch(() => {
                                    navigateToMatchmaking();
                                })
                        }
                    }}
                >
                    {selectOptions}
                </TextField>
            </FormControl>
        </Grid>
    )

}