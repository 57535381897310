import { Grid, Checkbox } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel';

const styles = {
    toggleSettingsBox: {
        backgroundColor: '#FFFFFF',
        textArea: {
            color: '#4B4E65',
        },
        mt: 1
    }
}

export default function ToggleSetting(props: any) {
    const { display_name, element, setSettingsArray, index, disabledState, navigateToMatchmaking } = props;

    return (
        <Grid item xs={12} lg={6} sx={styles.toggleSettingsBox} >
            <FormControlLabel disabled={disabledState}
                control={<Checkbox defaultChecked={JSON.parse(element['value'])}
                    onChange={(e: any) => {
                        element.Set(e.target.checked)
                            .catch(() => {
                                navigateToMatchmaking()
                            })

                        setSettingsArray((old: any) => {
                            let copy = [...old]
                            copy[index]['value'] = !(JSON.parse(element['value']));
                            return copy
                        })
                    }
                    } />}
                label={display_name}
            />
        </Grid>
    )

}