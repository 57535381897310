import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { Typography, Grid, Button, List } from '@mui/material';
import Placeholder from '../../../../Assets/Placeholder.png'

export default function ScanFailure(props: any) {
    const { handleListItemClick, selectedScanIndex, cargoResponse, cargoResponseIndex, openDetailsModal } = props

    const styles = {
        scanFailureButton: {
            '&.Mui-disabled': {
                backgroundColor: '#F2BB2D',
            }
        },
        scanFailureText: {
            fontWeight: 750,
            color: '#1A1B23',
        },
        barcodeButton: {
            '&.Mui-disabled': {
                backgroundColor: 'grey',
                color: 'white',
                borderRadius: '16px',
            },
        }, 
        timeButton: {
            '&.Mui-disabled': {
                backgroundColor: 'grey',
                color: 'white',
                borderRadius: '16px',
            },
        },
        barcodeImage: {
            display: "inline",
            pr: 1,
            pl: 0.5
        },
    }


    return (
        <ListItemButton
            selected={cargoResponseIndex === selectedScanIndex}
            onClick={(event) => {
                handleListItemClick(event, cargoResponseIndex)
                openDetailsModal()
            }}
        sx={{
            backgroundImage: `url(${Placeholder})`,
            backgroundSize: "contain",
            borderRadius: '12px',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundColor: '#E4E4E4',
            mb: 2,
            mt: -1,
            px: 0,
            border: 1,
            borderColor: '#FFFFFF',
            "&.Mui-selected": {
                border: 1,
                borderColor: '#00004B',
            },
            '&:hover': {
                border: 0.2,
                borderColor: '#00004B',
            },
        }}
        >
            <Grid container>
                <Grid container sx={{
                    pt: 6,
                    pb: 3
                }}
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item md={12} display="flex" justifyContent="center" alignItems="center" sx={{ pb: 2, pt: 2 }}>
                        <Button sx={styles.scanFailureButton} variant="contained" disabled >
                            <Typography component={'span'} sx={styles.scanFailureText}>&#9888; Scan Failure</Typography>
                        </Button>
                    </Grid>
                </Grid>
                <Grid container
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    sx={{
                        mt: -2,
                        mb: -2
                    }}
                >
                    <List>
                        <ListItem>
                            <Button disabled sx={styles.timeButton}>
                                <Typography component={'span'} sx={{ fontWeight: 550 }}>
                                    {cargoResponse.timeStamp.toLocaleTimeString()}
                                </Typography>
                            </Button>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </ListItemButton>
    )
}