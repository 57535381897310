import React, { useEffect, useState } from 'react';
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import { Grid } from '@mui/material';
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import useScreenSize from '../../../ts/useScreenSize';
import ScanHistoryMobile from './ScanHistoryMobile';
import { isMobile, BrowserView, MobileView } from 'react-device-detect';

const drawerBleeding = 150;

const Root = styled("div")(({ theme }) => ({
    height: "100%",
    backgroundColor:
        theme.palette.mode === "light"
            ? grey[100]
            : theme.palette.background.default,
}));

const StyledBox = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled("div")(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
    borderRadius: 3,
    position: "absolute",
    top: 8,
    left: "calc(50% - 15px)",
}));

export default function SwipeableEdgeDrawer(props: any) {
    const { window } = props;
    const [open, setOpen] = React.useState(false);
    const { cargoResponses, selectedScanIndex, setSelectedScanIndex, setOpenScanDetails, machine } = props
    const [mountDrawer, setMountDrawer] = useState(false);

    const screenSize = useScreenSize();

    const drawerBreak = 600;

    useEffect(() => {
        if (screenSize.width <= drawerBreak) {
            setMountDrawer(true)
        } else if (screenSize.width > drawerBreak) {
            setMountDrawer(false)
        }
    }, [screenSize])

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    // This is used only for the example
    const container =
        window !== undefined ? () => window().document.body : undefined;

    const openDetailsModal = function () {
        setOpenScanDetails(true);
    }

    return (
        <Root>
            <CssBaseline />
            <Global
                styles={{
                    ".MuiDrawer-root > .MuiPaper-root": {
                        height: `calc(80% - ${drawerBleeding}px)`,
                        overflow: "visible",
                    },
                }}
            />
            <SwipeableDrawer
                container={container}
                anchor="bottom"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: mountDrawer,
                }}
                onMouseDown={() => setOpen(true)}
            >
                <BrowserView>
                    <StyledBox
                        sx={{
                            position: 'absolute',
                            top: -drawerBleeding,
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            visibility: 'visible',
                            right: 0,
                            left: 0,
                            bottom: '0',
                            pointerEvents: "all"
                        }}
                    >
                        <Puller />
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography sx={{ p: 1, color: 'text.secondary' }}>Scan Results</Typography>
                        </Grid>
                        <Grid sx={{ overflow: 'auto', height: '100%' }}>
                            <ScanHistoryMobile cargoResponses={cargoResponses} selectedScanIndex={selectedScanIndex} setSelectedScanIndex={setSelectedScanIndex} setOpenScanDetails={setOpenScanDetails} openDetailsModal={openDetailsModal} machine={machine}></ScanHistoryMobile>
                        </Grid>
                    </StyledBox>
                </BrowserView>
                <MobileView>
                    <StyledBox
                        sx={{
                            position: 'absolute',
                            top: -drawerBleeding,
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            visibility: 'visible',
                            right: 0,
                            left: 0,
                            bottom: '0',
                        }}
                    >
                        <Puller />
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography sx={{ p: 1, color: 'text.secondary' }}>Scan Results</Typography>
                        </Grid>
                        <Grid sx={{ overflow: 'auto', height: '100%' }}>
                            <ScanHistoryMobile cargoResponses={cargoResponses} selectedScanIndex={selectedScanIndex} setSelectedScanIndex={setSelectedScanIndex} setOpenScanDetails={setOpenScanDetails} openDetailsModal={openDetailsModal} machine={machine}></ScanHistoryMobile>
                        </Grid>
                    </StyledBox>
                </MobileView>
            </SwipeableDrawer>
        </Root>
    );
}
