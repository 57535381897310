import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

const styles = {
    customFieldsBox: {
        backgroundColor: '#FFFFFF',
        textArea: {
            color: '#4B4E65'
        },
        mt: 1
    }
}

export default function CustomFieldAutoIncrement(props: any) {
    const { index, customField, updateCustomFieldSubmitValues, scanButtonPressed, cargoResponses, barcodeValue, incrementer, setIncrementer } = props
    // const [incrementer, setIncrementer] = useState<number>(0);
    const [incremented, setIncremented] = useState<boolean>(false);

    useEffect(() => {
        if (scanButtonPressed === true) {
            if (cargoResponses[0] && cargoResponses[0]['barcode'] === barcodeValue) {
                setIncrementer(incrementer + 1);
                setIncremented(true)
            } else {
                setIncrementer(1)
                setIncremented(true)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scanButtonPressed])

    useEffect(() => {
        if (incremented === true) {
            updateCustomFieldSubmitValues(index, incrementer)
            setIncremented(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [incremented])

    // Fires after cargoResponses are set - checks if it's a failed scan
    // if so set the incrementer back to 0.
    useEffect(() => {
        if (cargoResponses[0] && cargoResponses[0]['dimensionCode'] !== "0") {
            setIncrementer(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cargoResponses])

    const required = <Typography sx={{ color: 'red' }}>&nbsp;*&nbsp;</Typography>

    return (
        <Grid item md={11} xs={11} sx={styles.customFieldsBox}>
            <TextField color="primary" type="number" variant="outlined" size="small" fullWidth value={incrementer}
                onChange={(element) => {
                    updateCustomFieldSubmitValues(index, element.target.value)
                    setIncrementer(Number(element.target.value))
                }}
                InputProps={{
                    startAdornment: <InputAdornment sx={styles.customFieldsBox.textArea} position="start">{customField.name}  {customField.required ? required : ""}| </InputAdornment>,
                }}
            />
        </Grid>
    );
}