import { Grid } from "@mui/material";

const styles = {
    dimensionContainer: {
        border: 1,
        display: 'inline-flex',
        borderRadius: '5px',
        borderColor: '#D3D3DE',
    }
}

export default function CertificationDimensionGrid(props: any) {
    const { certificationResponse } = props;

    return (
        <Grid container sx={styles.dimensionContainer} >
            <Grid item xs={3} >
            </Grid>
            <Grid item xs={3} fontStyle="italic">
                Minimum
            </Grid>
            <Grid item xs={3} fontStyle="italic">
                Maximum
            </Grid>
            <Grid item xs={3} fontStyle="italic">
                Divisions
            </Grid>
            <Grid item xs={3} sx={{ pl: 2 }} fontStyle="italic">
                Length
            </Grid>
            <Grid item xs={3}>
                {certificationResponse['LengthBounds'][0]}
            </Grid>
            <Grid item xs={3}>
                {certificationResponse['LengthBounds'][1]}
            </Grid>
            <Grid item xs={3}>
                {certificationResponse['Precision']}
            </Grid>
            <Grid item xs={3} sx={{ pl: 2 }} fontStyle="italic">
                Width
            </Grid>
            <Grid item xs={3}>
                {certificationResponse['LengthBounds'][0]}
            </Grid>
            <Grid item xs={3}>
                {certificationResponse['LengthBounds'][1]}
            </Grid>
            <Grid item xs={3}>
                {certificationResponse['Precision']}
            </Grid>
            <Grid item xs={3} sx={{ pl: 2 }} fontStyle="italic">
                Height
            </Grid>
            <Grid item xs={3}>
                {certificationResponse['HeightBounds'][0]}
            </Grid>
            <Grid item xs={3}>
                {certificationResponse['HeightBounds'][1]}
            </Grid>
            <Grid item xs={3}>
                {certificationResponse['Precision']}
            </Grid>
        </Grid> 
    )
}