import { useEffect, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import ScanImage from './ScanImage';
import "./carousel.css";
import { ArrowLeft } from '@mui/icons-material';
import { ArrowRight } from '@mui/icons-material';

export default function ScanImageCarousel(props: any) {
    const { imagePaths, height, width } = props;
    const [items, setItems] = useState<any>([
        "https://cargospectre.blob.core.windows.net/assets/images/cargo-placeholder.png"
    ]);

    useEffect(() => {
        if (imagePaths.length) {
            let images = imagePaths.map((snap: any) => snap);
            setItems(images);
        } else {
            setItems(["https://cargospectre.blob.core.windows.net/assets/images/cargo-placeholder.png"])
        }
    
    }, [imagePaths]);

    const renderNextButton = () => {
        return <ArrowRight sx={{
            display: {
                xs: 'none',
                md: 'block'
        }}} 
        style={{ position: "absolute", right: 0, top: '45%', fontSize: 45, cursor: 'pointer', color: '#F48430' }} />
    };

    const renderPrevButton = () => {
        return <ArrowLeft 
            sx={{
                display: {
                    xs: 'none',
                    md: 'block'
                }
            }} 
        style={{ position: "absolute", left: 0, top: '45%', fontSize: 45, cursor: 'pointer', color: '#F48430' }} />
    };

    return (
        <AliceCarousel 
            mouseTracking
            infinite={true}
            renderPrevButton={renderPrevButton}
            renderNextButton={renderNextButton}
        > 
            {items.map((item: any, i: any) => {
                return <ScanImage key={i} item={item} height={height} width={width}></ScanImage>
            })}
        </AliceCarousel>   
    )
}