import ListItemButton from '@mui/material/ListItemButton';
import { Typography, Grid, Box, Button, ListItem, List, Slide } from '@mui/material';
import Barcode_Mini_Icon_White from '../../../../Assets/Barcode_Mini_Icon_White.png';
import { Cargo } from '../../../../ts/Cargo';
import { useState, useEffect } from 'react';

export default function ScanEvent(props: any) {
    const { handleListItemClick, selectedScanIndex, cargoResponse, cargoResponseIndex, openDetailsModal, machine, index } = props
    const [rawImagePaths, setRawImagePaths] = useState<string[]>(['https://cargospectre.blob.core.windows.net/assets/images/cargo-placeholder.png']);

    const styles = {
        barcode: {
            fontWeight: 650,
            color: '#636383',
            display: 'inline',
        },
        successCheck: {
            fontWeight: 600,
            color: '#FFFFFF',
            display: 'inline'
        },
        nullBox: {
            fontWeight: 600,
            display: 'inline'
        },
        timeSinceText: {
            fontWeight: 550,
            color: '#262D6D',
            display: 'inline'
        },
        barcodeImage: {
            display: "inline",
            pr: 1,
            pl: 0.5,
        },
        uploadButton: {
            '&.Mui-disabled': {
                backgroundColor: cargoResponse.uploaded == null ? 'grey' : cargoResponse.uploaded ? '#429254' : '#EFBC46',
                color: 'white',
                borderRadius: '16px',
                minWidth: '105px',
            },
        },
        exportButton: {
            '&.Mui-disabled': {
                backgroundColor: cargoResponse.exported == null ? 'grey' : cargoResponse.exported ? '#429254' : '#EFBC46',
                color: 'white',
                borderRadius: '16px',
                minWidth: '105px'
            },
        },
        barcodeButton: {
            '&.Mui-disabled': {
                backgroundColor: 'grey',
                color: 'white',
                borderRadius: '16px',
                minWidth: '105px'
            },
        },
        timeButton: {
            '&.Mui-disabled': {
                backgroundColor: 'grey',
                color: 'white',
                borderRadius: '16px',
                minWidth: '105px'
            },
        },
    }

    useEffect(() => {
        if (cargoResponse) {
            parseResponse(cargoResponse)
        } else {
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cargoResponse])

    async function retrieveFilePaths(responseArray: string[]) {
        return await Promise.all(responseArray.map(response => {
            return machine.connection.file(response)
        }))
    }

    async function parseResponse(cargoResponse: Cargo) {
        let filteredImages = cargoResponse.fileList.images.filter((image: any) => !image.includes('barcode'));

        await retrieveFilePaths(filteredImages)
            .then(paths => {
                paths = [...paths]
                setRawImagePaths(paths);
            })
    }

    return (
        <ListItemButton
            selected={cargoResponseIndex === selectedScanIndex}
            onClick={(event) => {
                handleListItemClick(event, cargoResponseIndex)
                openDetailsModal()
            }}
            sx={{
                backgroundImage: `url(${rawImagePaths[0]})`,
                backgroundSize: "cover",
                borderRadius: '12px',
                mb: 2,
                mt: -1,
                px: 0,
                border: 1,
                borderColor: '#FFFFFF',
                "&.Mui-selected": {
                    border: 1,
                    borderColor: '#00004B',
                },
                '&:hover': {
                    border: 0.2,
                    borderColor: '#00004B',
                },
            }}
            disableGutters
        >
            <Grid container
                direction="column"
                alignItems="flex-end"
                sx={{
                    mt: -2,
                    mb: -2
                }}
            >
                <List>
                    <ListItem>
                        {
                            cargoResponse.uploaded == null ?
                                <Button disabled>
                                    <Typography sx={{ opacity: '0%' }}>Null</Typography>
                                </Button>
                                :
                                index === 0 ?
                                    <Slide direction="right" in={true} mountOnEnter unmountOnExit >
                                        <Button disabled sx={styles.uploadButton}>
                                            <Typography component={'span'} sx={{ fontWeight: 600 }}>
                                                {
                                                    cargoResponse.uploaded ? <Typography sx={styles.successCheck}>&#10003; Uploaded</Typography> : <Typography display={'inline'} sx={{ fontWeight: 600 }}>&#9747; Uploaded</Typography>
                                                }
                                            </Typography>
                                        </Button>
                                    </Slide>
                                    :
                                    <Button disabled sx={styles.uploadButton}>
                                        <Typography component={'span'} sx={{ fontWeight: 600 }}>
                                            {
                                                cargoResponse.uploaded ? <Typography sx={styles.successCheck}>&#10003; Uploaded</Typography> : <Typography display={'inline'} sx={{ fontWeight: 600 }}>&#9747; Uploaded</Typography>
                                            }
                                        </Typography>
                                    </Button>
                        }
                    </ListItem>
                    <ListItem>
                        {
                            cargoResponse.exported == null ?
                                <Button>
                                    <Typography sx={{ opacity: '0%' }}>Null</Typography>
                                </Button>
                                :
                                index === 0 ?
                                    <Slide direction="right" in={true} mountOnEnter unmountOnExit >
                                        <Button disabled sx={styles.exportButton}>
                                            <Typography component={'span'} sx={{ fontWeight: 600 }}>
                                                {
                                                    cargoResponse.exported ? <Typography sx={styles.successCheck}>&#10003; Exported</Typography> : <Typography display={'inline'} sx={{ fontWeight: 600 }}>&#9747; Exported</Typography>
                                                }
                                            </Typography>
                                        </Button>
                                    </Slide>
                                    :
                                    <Button disabled sx={styles.exportButton}>
                                        <Typography component={'span'} sx={{ fontWeight: 600 }}>
                                            {
                                                cargoResponse.exported ? <Typography sx={styles.successCheck}>&#10003; Exported</Typography> : <Typography display={'inline'} sx={{ fontWeight: 600 }}>&#9747; Exported</Typography>
                                            }
                                        </Typography>
                                    </Button>
                        }
                    </ListItem>
                    <ListItem>
                        {cargoResponse.barcode !== "" ?
                            <Button disabled sx={styles.barcodeButton}>
                                <Typography alignContent={'left'} sx={{ fontWeight: 550 }}>
                                    <Box
                                        component="img"
                                        sx={styles.barcodeImage}
                                        alt="Barode:"
                                        src={Barcode_Mini_Icon_White}
                                    />
                                    {cargoResponse.barcode.length <= 5 ? cargoResponse.barcode : (cargoResponse.barcode.substr(0, 5) + '...')}
                                </Typography>
                            </Button>
                            :
                            <Button disabled sx={styles.barcodeButton}>
                                <Typography alignContent={'left'} sx={{ fontWeight: 'bold', fontStyle: 'italic' }}>
                                    <Box
                                        component="img"
                                        sx={styles.barcodeImage}
                                        alt="Barode:"
                                        src={Barcode_Mini_Icon_White}
                                    />
                                    None
                                </Typography>
                            </Button>
                        }
                    </ListItem>
                    <ListItem>
                        <Button disabled sx={styles.timeButton}>
                            <Typography component={'span'} sx={{ fontWeight: 550 }}>
                                {cargoResponse.timeStamp.toLocaleTimeString()}
                            </Typography>
                        </Button>
                    </ListItem>
                </List>
            </Grid>
        </ListItemButton>
    )
}