import { List } from '@mui/material'
import PictureScanEvent from './scanHistoryComponents/PictureScanEvent';
import React from 'react'
import TextScanEvent from './scanHistoryComponents/TextScanEvent';
import ScanFailure from './scanHistoryComponents/ScanFailure';

export default function ScanHistoryMobile(props: any) {
    const { selectedScanIndex, setSelectedScanIndex, cargoResponses, setOpenScanDetails, openDetailsModal, machine } = props

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedScanIndex(index);
    };

    return (
        <List>
            {cargoResponses.map((response: any, index: any) => {
                if (index === 0) {
                    if (cargoResponses[index].dimensionCode === "0") {
                        return (
                            // ****NOTE WHEN PASSING DOWN SINGLE SCAN RESPONSE CHANGE FROM PLURAL TO SINGULAR
                            // cargoResponses --> cargoResponse
                            <React.Fragment key={index}>
                                <TextScanEvent key={index} cargoResponse={cargoResponses[index]} scanFailure={false}
                                    cargoResponseIndex={index} handleListItemClick={handleListItemClick} selectedScanIndex={selectedScanIndex}
                                    openDetailsModal={openDetailsModal}></TextScanEvent>
                            </React.Fragment>
                        )
                    } 
                    else if (cargoResponses[index].dimensionCode !== "0") {
                        return (
                            <React.Fragment key={index}>
                                <TextScanEvent key={index} cargoResponse={cargoResponses[index]} scanFailure={true} handleListItemClick={handleListItemClick}
                                    cargoResponseIndex={index} selectedScanIndex={selectedScanIndex} setOpenScanDetails={setOpenScanDetails} openDetailsModal={openDetailsModal}></TextScanEvent>
                            </React.Fragment>
                        )
                    } else {
                        return <div key={index}>Unknown Item</div>
                    }
                }
                else {
                    if (cargoResponses[index].dimensionCode === "0") {
                        return (
                            // ****NOTE WHEN PASSING DOWN SINGLE SCAN RESPONSE CHANGE FROM PLURAL TO SINGULAR
                            // cargoResponses --> cargoResponse
                            <React.Fragment key={index}>
                                <PictureScanEvent key={index} cargoResponse={cargoResponses[index]} scanFailure={false}
                                    cargoResponseIndex={index} handleListItemClick={handleListItemClick} selectedScanIndex={selectedScanIndex}
                                    setOpenScanDetails={setOpenScanDetails} openDetailsModal={openDetailsModal} machine={machine}></PictureScanEvent>
                            </React.Fragment>
                        )
                    } else if (cargoResponses[index].dimensionCode !== "0") {
                        return (
                            <React.Fragment key={index}>
                                <ScanFailure key={index} cargoResponse={cargoResponses[index]} handleListItemClick={handleListItemClick}
                                    cargoResponseIndex={index} selectedScanIndex={selectedScanIndex} openDetailsModal={openDetailsModal}></ScanFailure>
                            </React.Fragment>
                        )
                    } else {
                        return <div key={index}>Unknown Item</div>
                    }
                }
            })}
        </List>
    )
}