import React from 'react'
import { useState, useEffect } from 'react';
import { Machine, get_preferred_connection, OfflineMachine, LocalHostConnection } from '../../ts/Connections';
import Grid from '@mui/material/Grid'
import { Typography, CircularProgress } from '@mui/material'
import MatchmakingCards from './components/matchmakingCards';
import { useNavigate } from "react-router-dom";

const MY_HOST = process.env.REACT_APP_MY_HOST || "https://spectre-licensing.com"

export default function Matchmaking(props: any) {
    const { setSelectedMachine, cookies, setIsLoggedIn, removeCookie } = props
    const [onlineMachines, setOnlineMachines] = useState<Array<Machine>>([]);
    const [localMachines, setLocalMachines] = useState<Array<Machine>>([]);
    const [offlineMachines, setOfflineMachines] = useState<Array<OfflineMachine>>([]);
    const [activeCard, setActiveCard] = useState<boolean>(true);
    const [machinesFetched, setMachinesFetched] = useState<boolean>(false);

    const DOMAIN = process.env.REACT_APP_DOMAIN || '.spectre-licensing.com'
    
    const navigate = useNavigate();

    useEffect(() => {
        setSelectedMachine(false)
        fetch(`${MY_HOST}/matchmaking/list`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then(data => data.json())
            .then(data => {
                if (data.matches == null) { return }
                data.matches.forEach((machine: any) => localStorage.setItem(machine.machineName, JSON.stringify(machine)))
                return data
            })
            .then(data => {
                var promises = [];
                for (const machine of data.matches) {
                    promises.push(
                    get_preferred_connection(machine, cookies.user64)
                        .then(data => {
                            if (data == null) {
                                let offlineMachine = new OfflineMachine(machine.machineName, machine.localIP, machine.publicIP, machine.matchmakingID, machine.location, machine.macAddress, machine.scanMode, machine.version)
                                console.log('machine did not ping true...!')
                                setOfflineMachines(old => { return [...old, offlineMachine] })
                                return
                            }
                            else if (data instanceof LocalHostConnection) {
                                let machine_object = new Machine(data, machine.machineName, machine.localIP, machine.publicIP, machine.matchmakingID, machine.location, machine.macAddress, machine.scanMode, machine.certification, machine.version)
                                setLocalMachines(old => {return [...old, machine_object]})
                            } else {
                                let machine_object = new Machine(data, machine.machineName, machine.localIP, machine.publicIP, machine.matchmakingID, machine.location, machine.macAddress, machine.scanMode, machine.certification, machine.version)
                                console.log('machine pinged true:', machine_object.machineName)
                                setOnlineMachines(old => { return [...old, machine_object] })
                            }
                        }))
                }
                return promises
            })
            .then((promises: any) => {
                Promise.all(promises).then(() => setMachinesFetched(true))
            })
            .catch((err: any) => {
                console.log(err);
                setIsLoggedIn(false);
                removeCookie('user64', { path: '/', domain: DOMAIN })
                alert("Error logging in, please try again")
                navigate('/login')
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const styles = {
        page: {
            backgroundColor: "#F5F5F8"
        },
        text: {
            fontWeight: 700,
            justifyContent: {
                md: 'flex-start',
                xs: 'center'
            }
        },
        gridContainer: {
            justifyContent: {
                md: 'flex-start',
                xs: 'center'
            },
            mt: {
                md: 6,
                xs: 5
            },
            ml: {
                md: 18
            }
        }
    }

    return (
        <Grid container sx={styles.page} style={{paddingTop: 68}}>
            {!machinesFetched ?
                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    sx={{
                        position: 'absolute' as 'absolute',
                        top: '40%',
                    }}
                >
                    <CircularProgress size={200} sx={{ color: "#F48430" }} />
                </Grid>
                :
                <React.Fragment>
                    <Grid item md={12} container
                        sx={styles.gridContainer}
                    >
                        {localMachines.length > 0 ? <Typography variant="h5" color="#262D6D" sx={styles.text}>This Spectre</Typography> : ""}
                    </Grid>
                    <Grid item md={12} container>
                        <MatchmakingCards onlineMachines={localMachines} machine={Machine} setSelectedMachine={setSelectedMachine} activeCard={activeCard} setActiveCard={setActiveCard}></MatchmakingCards>
                    </Grid>
                    <Grid item md={12} container
                        sx={styles.gridContainer}
                    >
                        {onlineMachines.length > 0 ? <Typography variant="h5" color="#262D6D" sx={styles.text}>Active Spectres</Typography> : ""}
                    </Grid>
                    <Grid item md={12} container>
                        <MatchmakingCards onlineMachines={onlineMachines} machine={Machine} setSelectedMachine={setSelectedMachine} activeCard={activeCard} setActiveCard={setActiveCard}></MatchmakingCards>
                    </Grid>
                    <Grid item md={12} container
                        sx={styles.gridContainer}>
                        <Typography variant="h5" color="#262D6D" sx={styles.text}>Inactive Spectres</Typography>
                    </Grid>
                    <Grid item md={12} container>
                        <MatchmakingCards onlineMachines={offlineMachines} machine={Machine} setSelectedMachine={setSelectedMachine} get_preferred_connection={get_preferred_connection} setOnlineMachines={setOnlineMachines} setOfflineMachines={setOfflineMachines} cookies={cookies} offlineMachines={offlineMachines}></MatchmakingCards>
                    </Grid>
                </React.Fragment>
            }
        </Grid>
    );
}